import styled from "styled-components";

const FeaturesImage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  left: 0px;
  right: 0px;
  //flex:1;
  width: 100%;
  height: 55px;
  max-width: 1280px;
  margin-top: -.23rem;
  background-color: #1AA6E1;
  @media only screen and (max-width: 768px) {
    flex-direction: row;
    flex-wrap:wrap;
    justify-content: center;
  }
`;
const EachFeature = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  //width: 10%;
  //height: 100%;
  margin-bottom: .5rem;
  margin-top: .5rem;
  @media only screen and (max-width: 768px) {
    margin: 0rem 0rem;
  }
  
  & span {
    color: #637381;
    text-align: center;
    font-family: 'Roboto';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 0.7rem;
    
    }
  }
  & p {
    color: #fff;

    text-align: center;
    font-family: 'Roboto';
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: .5rem;
    @media only screen and (max-width: 768px) {
      font-weight: 400;
      font-size: 0rem;
    
    }
  }
  &:hover {
    opacity: 1;
  }
  @media only screen and (max-width: 768px) {
    width: 25%;
    //margin: 1rem auto;
    
  }
  @media only screen and (max-width: 420px) {
    width: 25%;
    //margin: 1rem auto;
    
  }
`;
const EachFeatureImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
  @media only screen and (max-width: 768px) {
    width: 32px;
    height: 32px;
    object-fit: contain;
    //margin-bottom: .3rem;
    //height: 150px;
    
  }
`;
function FeaturesText() {
  return (
    <FeaturesImage>
      <EachFeature>
        <EachFeatureImage src="/catalog/FeatureImages/24-hours-support.png"/>
        <p>24/7 DELIVERY</p>
      </EachFeature>
      <EachFeature>
        <EachFeatureImage src="/catalog/FeatureImages/restaurant.png"/>
        <p>2500 RESTAURANTS</p>
      </EachFeature>
      <EachFeature>
        <EachFeatureImage src="/catalog/FeatureImages/mobile-app.png"/>
        <p>ORDER WITH APP</p>
      </EachFeature>
      <EachFeature>
        <EachFeatureImage src="/catalog/FeatureImages/fast-delivery.png"/>
        <p>FAST DELIVERY</p>
      </EachFeature>
    </FeaturesImage>
  );
}

export default FeaturesText;
