import MyAccount from "./pages/MyAccount";
import Search from "./pages/Search";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import { Routes, Route } from "react-router-dom";
import Card from "./pages/Card";
import ShoppingCart from "./pages/ShoppingCart";
import Brands from "./pages/Brands";
import Checkout from "./pages/Checkout";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Charge from "./pages/Charge";
import Maintenance from "./pages/Maintenance";
//#region REDUX
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "./redux/actions/userActions";
import * as settingsActions from "./redux/actions/settingsActions";
import useWindowWidthAndHeight from "./utilities/hooks/useWindowWidthAndHeight";
import OrcaBottomModal from "./components/GlobalComponents/OrcaBottomModal";
import styled from "styled-components";
import { colors } from "./res/values/values";

import Fab from "@mui/material/Fab";
import UpIcon from "@mui/icons-material/KeyboardArrowUp";
import CookieIcon from '@mui/icons-material/Cookie';
import Header from "./components/GlobalComponents/Header";
import Footer from "./components/GlobalComponents/Footer";
import ImageSlider from "./components/GlobalComponents/ImageSlider";
import CategorySearch from "./pages/CategorySearch";
//#region Styles
const Container = styled.div`
   display:flex;
   flex: 1;
   flex-direction: column;
   justify-content:space-around;align-items:center;
   
   font-family: "Roboto";
   background-color:#fff ;
`;
const Content = styled.div`
    display:flex;
   justify-content:center;
   align-items:flex-start;
   flex: 1;
   //width: 100%;
   //max-width: 1280px;
   min-height: 50rem;
   margin:1.5rem; 
`;
const Left = styled.div`
   background-color: green;
   width: 25%;
`;
const Right = styled.div`
   background-color: yellow;
   width: 75%;
`;
const LogoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;
const LogoImage = styled.img`
  height: 5rem;
  width: 5rem;
  object-fit: contain;
`;
const Button = styled.div`
  border: none;
  padding: 15px;
  background-color: ${colors.primaryColor};
  color: white;
  margin-top: 10px;
  cursor: pointer;
  & a {
    color: white;
    text-decoration-line: none;
  }
`;
const FabContent = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  justify-content:space-around;
  position: sticky;
  background-color: transparent;
  bottom: 0;
  z-index: 100;
`;
const CookieTextBlock = styled.div`
  height:20rem ;
  overflow-y:scroll ;
  display: block;
  flex-direction: column;
  
`;
const CookieModal = styled.div`
  display:flex ;
  flex-direction:column ;
  padding:2rem ;
  line-height: 24px;
  & p {
    font-size: 15px;
    margin: 10px 0 16px 0;
    font-weight:400 ;
    
  }
  & h3 {
    color: black;
    text-decoration-line: underline;
    font-weight:800 ;
    font-size:14 ;
    margin:5px ;
  }
  & h4 {
    color: black;
    text-decoration-line: underline;
    font-weight:700 ;
    font-size:14 ;
    margin-top:1.5rem ;
  }
  
`;
const CookieButtons = styled.div`
  display: flex;
  flex-direction:column ;
  flex: 1;
  justify-content: space-between;
  margin-top:2rem ;
  @media only screen and (min-width: 600px) {
    flex-direction: row ;
  }
`;
const CookieButtonActive = styled.div`
  display: flex;
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  justify-content:center ;
  align-items: center;
  border-width: 2px;
  border-style: solid;
  background-color: ${colors.primaryColor};
  border-color: #dddddd #dddddd #b3b3b3 #b7b7b7;
  color: #fff;
  border-radius: 2px;
  padding: 8px;
  margin-top: 5px;
  cursor: pointer;
  @media only screen and (min-width: 600px) {
    margin-right: 10px;
  }
  &:hover {
    background-color: ${colors.whiteColor};
    color: ${colors.darkcolor};
    border-color:${colors.darkcolor};
  }
`;
const CookieButtonPassive = styled.div`
  display: flex;
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  justify-content:center ;
  align-items: center;
  border-width: 2px;
  border-style: solid;
  background-color: ${colors.whiteColor};
  border-color: ${colors.primaryColor};
  color: ${colors.primaryColor};
  border-radius: 2px;
  padding: 8px;
  margin-top: 5px;
  cursor: pointer;
  @media only screen and (min-width: 600px) {
    margin-right: 10px;
  }
  &:hover {
    background-color: ${colors.primaryColor};
    color: ${colors.whiteColor};
  }
`;
const StoreLocationModal = styled.div`
  display:flex ;
  flex-direction:column ;
  padding:2rem ;
  line-height: 24px;
  & p {
    font-size: 15px;
    margin: 10px 0 16px 0;
    font-weight:400 ;
    
  }
  & h3 {
    color: black;
    text-decoration-line: underline;
    font-weight:800 ;
    font-size:14 ;
    margin:5px ;
  }
  & h4 {
    color: black;
    text-decoration-line: underline;
    font-weight:700 ;
    font-size:14 ;
    margin-top:1.5rem ;
  }
  
`;
function App2() {
  return (
    <Container>
        <Header /> 
          <Content>
              <Routes>
                 
                {false ? (
                  <Route path="/" element={<Maintenance />} />
                ) :  (
                  <Route path="/" element={<Home />} />
                )}
                <Route path="/route=shop/" element={<Home />} />
                <Route path="*" element={<NoPage />} />
                <Route path="/notfound" element={<NoPage />} />
                <Route path="/account" element={<MyAccount />}>
                  <Route path=":page" element={<MyAccount />} />
                </Route>
                {/* Category Route */}
                <Route path="/product-categories/" element={<CategorySearch />} >
                  <Route path=":categoryseodescription" element={<CategorySearch />} />
                </Route>
                {/* Category Route */}
                <Route path="/search" element={<Search />} />
                {/* 
                <Route path="/route=product/card" element={<Card />}>
                  <Route path=":productid" element={<Card />} />
                </Route>
                */}
                <Route path="/" element={<Card />}>
                  <Route path=":productid" element={<Card />} />                  
                </Route>
                <Route path="/checkout/cart" element={<ShoppingCart />} />
                <Route path="/route=checkout/checkout" element={<Checkout />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/charge" element={<Charge />} />
                <Route path="/brands" element={<Brands />} />
              </Routes>
          </Content>
          <ImageSlider type={"bottom"} />
          <Footer/>
          
    </Container>
  )
}

export default App2