import styled from "styled-components";
import useWindowWidthAndHeight from "../../utilities/hooks/useWindowWidthAndHeight";
import { useNavigate } from "react-router-dom";
const FeaturesImage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  left: 0px;
  right: 0px;
  flex: 1;
  width: 100%;
  padding-bottom: 3rem;
  padding-top: 3rem;
  background-color: white;
  margin-bottom: 1rem;
  @media only screen and (max-width: 820px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 0px;
  right: 0px;
  flex: 1;
  width: 100%;
  max-width: 1280px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
`;
const HeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //margin-bottom: 2rem;
  //margin-top: 2rem;
  text-align: center;
  height: 163px;
  & h1 {
    color: #212b36;

    text-align: center;
    font-family: "Roboto Slab";
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 1.6rem;
    }
    & span {
      color: #1aa6e1;

      text-align: left;
      font-family: "Roboto Slab";
      font-size: 2.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @media only screen and (max-width: 968px) {
        font-weight: 450;
        font-size: 1.5rem;
      }
    }
  }
  & p {
    color: #1aa6e1;

    text-align: center;
    font-family: "Roboto";
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 0.5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 1.6rem;
    }
  }
  & span {
    color: #9a9a9a;
    text-align: center;
    font-family: "Roboto";
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 1rem;
    }
  }
`;
const LabelDiv = styled.div`
  display: flex;
  flex-direction: row;
`;
const EachFeature = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
  width: 15.25rem;
  height: 15.25rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  background-color: ${(props) => {
    switch (props.bgc) {
    case 1:
      return "#19B4F6";
    case 2:
      return "#29B22E";
    case 3:
      return "#F66919";
    default:
      return "#F219F6";
  }
  }};
  @media only screen and (max-width: 720px) {
    width: 12.25rem;
    height: 12.25rem;
    }
  text-align: center;
  & p {
    color: #fff;

    text-align: center;
    font-family: "Roboto";
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 0.5rem;
    @media only screen and (max-width: 720px) {
      font-weight: 400;
      font-size: .8rem;
    }
  
  }
  
`;
const RegisterButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  background-color:#F66919;
  text-align: center;
  cursor: pointer;
  & p {
    color: #fff;
    text-align: center;
    font-family: "Roboto Slab";
    font-size: 1.625rem;
    font-style: normal;
    font-weight: Bold;
    line-height: normal;
    margin: .5rem 1rem;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 1.2rem;
    }
  }
`;
function RangeComponent() {
  const navigate = useNavigate();
  const { width } = useWindowWidthAndHeight();
  return (
    <Container>
      <HeaderDiv>
        <LabelDiv>
        <h1>
        DISCOVER <span>OUR EXTENSIVE PRODUCT RANGE </span>
        </h1>
        </LabelDiv>
        <span>Quality, Variety, and Sustainability for Your Business</span>
      </HeaderDiv>

      <FeaturesImage>
        <EachFeature bgc={1}>
          <p>Over 1000 Products<br/>Including<br/>Fresh, Chilled, Frozen,<br/>And Ambient Options</p>
        </EachFeature>
        

        <EachFeature bgc={2}>
          <p>Extensive Selection Of<br />Eco-Friendly<br />And Sustainable Packaging</p>
        </EachFeature>
        
        <EachFeature bgc={3}>
          <p>Diverse Assortment Of<br />Wines, Raki, Beer<br />And Soft Drinks</p>
        </EachFeature>
        
        <EachFeature bgc={4}>
          <p>Popular Big<br />Brands Alongside<br />Affordable<br />In-house Brands</p>
        </EachFeature>
      </FeaturesImage>
      <RegisterButton onClick={()=> { navigate("/account/register") }}><p>Register Now</p></RegisterButton>
    </Container>
  );
}

export default RangeComponent;
