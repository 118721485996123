import styled from "styled-components";
// #region Style
const EachFeature = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  width: 100%;
  @media only screen and (max-width: 768px) {
    margin: 1rem 1rem;
  }
  & span {
    color: #212b36;
    text-align: left;
    font-family: "Roboto";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4rem;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 0.9rem;
    }
  }
  & h3 {
    color: #5f5f5f;
    text-align: left;
    font-family: "Roboto";
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 0.9rem;
    }
  }
  & h1 {
    color: #1aa6e1;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    @media only screen and (max-width: 968px) {
      font-weight: 500;
      font-size: 1.5rem;
    }
  }
  & h2 {
    color: #212b36;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    margin-left: 0.5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 500;
      font-size: 1.5rem;
    }
  }
  & h4 {
    color: #212b36;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    @media only screen and (max-width: 968px) {
      font-weight: 450;
      font-size: 1.5rem;
    }
    & span {
      color: #1aa6e1;

      text-align: left;
      font-family: "Roboto Slab";
      font-size: 2.18rem;
      font-style: normal;
      font-weight: 450;
      line-height: normal;
      @media only screen and (max-width: 968px) {
        font-weight: 450;
        font-size: 1.5rem;
      }
    }
  }
  & h5 {
    color: #1aa6e1;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    margin-left: 0.5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 450;
      font-size: 1.5rem;
    }
  }
  &:hover {
    opacity: 1;
  }
`;
const LabelDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const EachCategoryImage = styled.img`
  width: 100%;
  object-fit: contain;
  margin-bottom: 1.2rem;
 
`;
// #endregion
function MobileMarketSectorComponent() {
  return (
    <EachFeature>
      <LabelDiv>
        <h4>CATERING EXCELLENCE FOR <span>EVENTS AND MOBILE CATERING</span></h4>
      </LabelDiv>

      <h3>Your Trusted Partner For All Catering Needs</h3>
      <EachCategoryImage
        src={"/catalog/ShopBySector/mobile-catering.png"}
      ></EachCategoryImage>
      <span>
        Best Catering is honoured to cater to some of the UK's top festivals and
        event caterers, providing chef-quality food to a multitude of mobile
        catering operations and events. Recognizing the critical role of
        dependable suppliers in ensuring flawless event execution, we bring over
        15 years of expertise and offer an extensive range of over 2,000
        products. From fresh and chilled items to frozen, ambient, and non-food
        products, we serve as your comprehensive and trustworthy supplier.
      </span>
      <br/>
      <span>
        Ordering is effortless and quick through our website and app. Choose us
        for quality service and a rich variety of products.
      </span>
      <br/>
      <span>
        Additionally, we provide an extensive range of packaging and reusable
        items to support eco-friendly practices for all event caterers. You can
        also benefit from our brand, Kings Pack, which offers regular boxes in
        all sizes, specifically designed for pizza shops and takeaway
        restaurants. For all your event and mobile catering requirements, you
        can count on Best Catering Ltd.
      </span>
      <br/>
      <LabelDiv>
        <h4>FESTIVALS</h4>
      </LabelDiv>
      <span>
        Each summer, we assist thousands of festival caterers in satisfying their hungry crowds with top-notch products and convenient delivery directly to festival locations.
      </span>
      <LabelDiv>
        <h4>REASONS TO CHOOSE <span>US FOR YOUR FOOD SERVICE NEEDS</span></h4>
      </LabelDiv>
      <span>Discover why we're your preferred food service provider:</span>
      <span>
        ✓ Comprehensive event essentials in one convenient place.
      </span>
      <span>
        ✓ Effortless online ordering via our user-friendly app.
      </span>
      <span>
        ✓ Swift collection and delivery services.
      </span>
      <span>
        ✓ Exclusive savings available online.
      </span>
      <br/>
      <span>
        <strong>
          {" "}
          ***Delivery days can differ based on where you are located.
        </strong>
      </span>
    </EachFeature>
  );
}

export default MobileMarketSectorComponent;
