import styled from "styled-components";


const EachFeature = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 163px;
  width: 100%;

  @media only screen and (max-width: 768px) {
    margin: 1rem 1rem;
  }
  
  & span {
    color: #9A9A9A;
    text-align: center;
    font-family: 'Roboto';
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top:.5rem ;
    
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: .9rem;
    
    }
  }
  & h1 {
    color: #212B36;

    text-align: center;
    font-family: 'Roboto Slab';
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 1.5rem;
    
    }
  }
  & p {
    color: #1AA6E1;

    text-align: center;
    font-family: 'Roboto Slab';
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: .5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 1.5rem;
    
    }
  }
  &:hover {
    opacity: 1;
  }
`;
const LabelDiv = styled.div`
  display: flex;
  flex-direction: row; 
  @media only screen and (max-width: 768px) {
    flex-direction: column;  
  }
`;
function FeaturesText() {
  return (
        <EachFeature>
            <LabelDiv>
            <h1>ORDER FROM A SELECTION OF OVER</h1>
            <p>5000 PRODUCTS</p>
            </LabelDiv>
          
          <span>Get Your Favourite Brands At The Best Wholesale Prices</span>
        </EachFeature>
  );
}

export default FeaturesText;
