import styled from "styled-components";
import { useNavigate } from "react-router-dom";

//#region
const CategoryDiv = styled.div`
  //margin-top: 20rem;
  //margin: 5px 10px;
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  //margin-top: 3rem !important;
`;

const CategoriesContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  //margin-top: .5rem !important;
  @media only screen and (max-width: 640px) {
    //height: 100px;
    //width: 380px;
  }
`;
const Divider = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #cacaca;
`;
const EachCategory = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  
  //align-items: center;
  width: 24%;
  //min-height: 12rem !important;

  margin-bottom: 1rem !important;
  @media only screen and (max-width: 768px) {
    margin-bottom: 0.5rem !important;
  }
  //margin: 2rem .1rem;
  cursor: pointer;
  & span {
    color: #212b36;

    font-family: "Roboto";
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    margin-top: 0.5rem;
    @media only screen and (max-width: 768px) {
      font-size: 0.7rem;
      margin-left: .3rem;
      //visibility: hidden;
    }
  }
  & p {
    color: #9a9a9a;

    font-family: "Roboto";
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    margin-top: 0.3rem;
    @media only screen and (max-width: 768px) {
      font-size: 0.5rem;
      margin-left: .3rem;
      //visibility: hidden;
    }
  }
  &:hover {
    & span {
      color: black !important;
    }
  }
  @media only screen and (min-width: 640px) and (max-width: 980px) {
    width: 20%;
    margin: 0.5rem;
  }
  @media only screen and (max-width: 640px) {
    width: 48%;
    //margin: 0.5rem;
  }
`;
const Categories = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  text-align: start;
`;
const EachCategoryImage = styled.img`
  width: 100%;
  object-fit: contain;
  
`;
const HeaderDiv = styled.div`
   display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //margin-bottom: 2rem;
    //margin-top: 2rem;
    text-align: center;
    height: 163px;

  & h1 {
    color: #212b36;

    text-align: center;
    font-family: "Roboto Slab";
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 1.6rem;
    }
  }
  & p {
    color: #1aa6e1;

    text-align: center;
    font-family: "Roboto Slab";
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 0.5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 1.6rem;
    }
  }
  & span {
    color: #9A9A9A;
    text-align: center;
    font-family: 'Roboto';
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 1rem;
    
    }
  }
`;
const LabelDiv = styled.div`
  display: flex;
  flex-direction: row;
`;
//#endregion
function ShopBySector(props) {
  const navigate = useNavigate();
  return (
    <CategoryDiv>
      <HeaderDiv>
        <LabelDiv>
          <h1>SHOP BY</h1>
          <p>SECTOR</p>
        </LabelDiv>
        <span>We Sell Wholesale Food Products To Various Sectors In the Food Industry.</span>
      </HeaderDiv>
      <CategoriesContent>
        <Categories>
          <EachCategory onClick={()=> { navigate("/shopbysector/restaurant") }}>
            <EachCategoryImage
              loading="lazy"
              src={"/catalog/ShopBySector/restaurant-dining.png"}
            ></EachCategoryImage>
            <span>Restaurant and Casual Dinings</span>
            <p>
              Your All-In-One Solution For Restaurants & Casual Dinings Needs ...
            </p>
          </EachCategory>
          <EachCategory onClick={()=> { navigate("/shopbysector/mobilemarket") }}>
            <EachCategoryImage
              loading="lazy"
              src={"/catalog/ShopBySector/mobile-catering.png"}
            ></EachCategoryImage>
            <span>Events and Mobile Market</span>
            <p>
              Catering Excellence For Events And Mobile Catering ...
            </p>
          </EachCategory>
          <EachCategory onClick={()=> { navigate("/shopbysector/hotels") }}>
            <EachCategoryImage
              loading="lazy"
              src={"/catalog/ShopBySector/hotels.png"}
            ></EachCategoryImage>
            <span>Hotels</span>
            <p>
              Your Ultimate Partner For Hotel Catering And Supplies ...
            </p>
          </EachCategory>
          <EachCategory onClick={()=> { navigate("/shopbysector/pubandbars") }}>
            <EachCategoryImage
              loading="lazy"
              src={"/catalog/ShopBySector/bars.png"}
            ></EachCategoryImage>
            <span>Pubs & Bars</span>
            <p>
              Elevating Pubs And Bars: Your Comprehensive Supplier For All Essentials ...
            </p>
          </EachCategory>
          <EachCategory onClick={()=> { navigate("/shopbysector/takeaway") }}>
            <EachCategoryImage
              loading="lazy"
              src={"/catalog/ShopBySector/takeaways.png"}
            ></EachCategoryImage>
            <span>Takeaways</span>
            <p>
              Trusted Wholesale Supplier For London Takeaways: Best Catering ...
            </p>
          </EachCategory>
          <EachCategory onClick={()=> { navigate("/shopbysector/cafe") }}>
            <EachCategoryImage
              loading="lazy"
              src={"/catalog/ShopBySector/cafe-and-sandwich.png"}
            ></EachCategoryImage>
            <span>Cafes & Sandwich Bars</span>
            <p>
              Best Catering: Excellence In The World Of Sandwiches ...
            </p>
          </EachCategory>
          <EachCategory onClick={()=> { navigate("/shopbysector/independent") }}>
            <EachCategoryImage
              loading="lazy"
              src={"/catalog/ShopBySector/independent.png"}
            ></EachCategoryImage>
            <span>Independent Markets</span>
            <p>
              Trusted Wholesale Supplier For London Food Businesses: Best Catering ...
            </p>
          </EachCategory>
          <EachCategory onClick={()=> { navigate("/shopbysector/hospital") }}>
            <EachCategoryImage
              loading="lazy"
              src={"/catalog/ShopBySector/hospital.png"}
            ></EachCategoryImage>
            <span>Hospital and Care Home Catering</span>
            <p>
              Hospital And Care Home Catering: Dependable Wholesale Solutions For London Food Services ...
            </p>
          </EachCategory>
        </Categories>
      </CategoriesContent>
    </CategoryDiv>
  );
}
export default ShopBySector;
