/* eslint-disable jsx-a11y/iframe-has-title */

import styled from "styled-components";
import useForm from "../../utilities/hooks/useForm";
import { validateInfo } from "../../utilities/helpers";
import { colors } from "../../res/values/values";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { forwardRef, useState } from "react";
import { useNavigate } from "react-router-dom";
//#region Styles

const Container = styled.div``;
const DivContent = styled.div`
  width: 50%;
  margin: 1rem;
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`;
const ContactUsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4rem;
  & p {
    color: #777;
    margin-bottom: 0.5rem;
    font-size: 14px;
    line-height: 1.58em;
    //text-transform: capitalize;
    letter-spacing: 1px;
    font-weight: 400;
  }
  & h1 {
    margin-bottom: 1rem;
    color: ${colors.primaryColor};
    font-size: 24px;
    //font-weight: 700;
    text-transform: capitalize;
  }
  & h4 {
    color: ${colors.primaryColor};
    margin-bottom: 0.5rem;
    font-size: 15px;
    line-height: 1.58em;
    //text-transform: capitalize;
    letter-spacing: 1px;
    //font-weight: 600;
    & span {
      color: #777;
      margin-bottom: 0.5rem;
      font-size: 14px;
      line-height: 1.58em;
      //text-transform: capitalize;
      letter-spacing: 1px;
      font-weight: 400;
    }
    & a {
      color: #777;
      margin-bottom: 0.5rem;
      font-size: 14px;
      line-height: 1.58em;
      //text-transform: capitalize;
      letter-spacing: 1px;
      font-weight: 400;
      text-decoration: none;
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 10px 0;
  padding: 10px;
  border: 1px;
  border-color: lightgray;
  border-style: solid;
  &:focus {
    outline: none !important;
    border-color: ${colors.primaryColor};
  }
`;
const MultiInput = styled.textarea`
  margin: 10px 0;
  padding: 10px;
  border: 1px;
  height: 15vw;
  border-color: lightgray;
  border-style: solid;
  text-align: start;
  &:focus {
    outline: none !important;
    border-color: ${colors.primaryColor};
  }
`;
const Button = styled.input`
  color: ${colors.whiteColor};
  font-size: 15px;
  font-weight: 600;
  border: none;
  padding: 15px 20px;
  background-color: ${colors.primaryColor};
  opacity: 80%;
  margin-bottom: 10px;
  cursor: pointer;
`;

//#endregion
const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function ContactContent(props) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const sendContactMail = async (values) => {
    let url = `${process.env.REACT_APP_API}/mail`;
    let rv = false;
    await fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        mail: values.email,
        name: values.name,
        message: values.message,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setOpen(true);
        setTimeout(function () {
          navigate("/");
        }, 2000);
      })
      .catch((err) => {
        let error = JSON.parse(err.message);
      });
  };

  const { handleChange, handleSubmit, values, errors } = useForm(
    sendContactMail,
    validateInfo
  );
  return (
    <Container>
      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3454.30993565577!2d-0.052091108020357775!3d51.60316830801451!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761f918e3805f7%3A0x15d02085e063a2df!2sBest%20Catering!5e0!3m2!1sen!2suk!4v1683707337439!5m2!1sen!2suk"
          width="100%"
          height="450"
          style={{ border: 0, marginTop: 5 }}
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>
      <ContactUsDiv>
        <DivContent>
          <h1> Contact Us</h1>
          <h4>Best Catering Food Service</h4>
          <p>
            Unit 5-9 Leeside Industrial Estate
            <br />
            Garman Road, Tottenham, London N17 0QH
          </p>
          <h4>
            Email :{" "}
            <a href="mailto:sales@bestcateringuk.com">
              sales@bestcateringuk.com
            </a>
          </h4>
          <h4>
            Phone : <span>020 888 555 94</span>
          </h4>
          <h4>
            Fax : <span>020 888 554 09</span>
          </h4>
          <h4>Working Hours of a Sales Office</h4>

          <h4>Weekdays</h4>
          <h4>
            Monday - Friday: <span>09:00 AM - 07:00 PM</span>
          </h4>
          <h4>Weekends</h4>
          <h4>
            Sunday: <span>10:00 AM - 07:00 PM</span>
          </h4>
        </DivContent>
        <DivContent>
          <Form onSubmit={handleSubmit}>
            <Input
              name="email"
              onChange={handleChange}
              type="text"
              placeholder="email"
              value={values.email}
            />
            {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
            <Input
              name="name"
              onChange={handleChange}
              type="text"
              placeholder="name"
              value={values.name}
            />
            {errors.name && <p style={{ color: "red" }}>{errors.name}</p>}
            <MultiInput
              name="message"
              onChange={handleChange}
              type="text"
              placeholder="your message (optional)"
              value={values.message}
            />
            {errors.message && <p style={{ color: "red" }}>{errors.message}</p>}
            <Button type="submit" value="SUBMIT"></Button>
          </Form>
        </DivContent>
      </ContactUsDiv>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Sending email successful !
          </Alert>
        </Snackbar>
      </Stack>
    </Container>
  );
}

export default ContactContent;
