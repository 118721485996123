import styled from "styled-components";
// #region Style
const EachFeature = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  width: 100%;
  @media only screen and (max-width: 768px) {
    margin: 1rem 1rem;
  }
  & span {
    color: #212b36;
    text-align: left;
    font-family: "Roboto";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4rem;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 0.9rem;
    }
    & span {
      color: #1aa6e1;
    }
  }
  & h3 {
    color: #5f5f5f;
    text-align: left;
    font-family: "Roboto";
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 0.9rem;
    }
  }
  & h1 {
    color: #212b36;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    @media only screen and (max-width: 968px) {
      font-weight: 500;
      font-size: 1.5rem;
    }
  }
  & h2 {
    color: #1aa6e1;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    margin-left: 0.5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 500;
      font-size: 1.5rem;
    }
  }
  & h4 {
    color: #212b36;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    @media only screen and (max-width: 968px) {
      font-weight: 450;
      font-size: 1.5rem;
    }
    & span {
      color: #1aa6e1;

      text-align: left;
      font-family: "Roboto Slab";
      font-size: 2.18rem;
      font-style: normal;
      font-weight: 450;
      line-height: normal;
      @media only screen and (max-width: 968px) {
        font-weight: 450;
        font-size: 1.5rem;
      }
    }
  }
  & h5 {
    color: #1aa6e1;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    margin-left: 0.5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 450;
      font-size: 1.5rem;
    }
  }
  &:hover {
    opacity: 1;
  }
`;
const LabelDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const EachCategoryImage = styled.img`
  width: 100%;
  object-fit: contain;
  margin-bottom: 1.2rem;
  
`;
// #endregion
function IndependentMarketSectorComponent() {
  return (
    <EachFeature>
      <LabelDiv>
        <h4>
          TRUSTED WHOLESALE SUPPLIER FOR LONDON FOOD BUSINESSES: BEST CATERING
        </h4>
      </LabelDiv>

      <h3>
        Comprehensive Fresh Produce And Grocery Solutions For Independent
        Markets And Food Establishments
      </h3>
      <EachCategoryImage
        src={"/catalog/ShopBySector/independent.png"}
      ></EachCategoryImage>
      <span>
        Best Catering has been a trusted supplier to UK food businesses for over
        15 years. As a dedicated partner to the food industry, we understand the
        unique needs of various food establishments in London and are committed
        to providing the highest quality products and services.
      </span>
      <br />
      <span>
        At Best Catering, we cater to all the fresh fruit and vegetable needs of
        food businesses. Our extensive range of produce ensures that you can
        find everything you need to keep your menus fresh and appealing. We
        provide a variety of tomatoes, from cherry to beefsteak, ensuring that
        every dish can have the perfect tomato. Additionally, we offer 10kg
        sacks of onions and potatoes at the most competitive prices, making it
        easy to stock up on these kitchen essentials without breaking the bank.
      </span>
      <br />
      <span>
        Our team of experts is the most knowledgeable in London's catering
        supply sector. We pride ourselves on our exceptional customer service
        and our ability to source the finest produce. Our seasonal fruit and
        vegetable section is always open, ensuring you have access to the
        freshest ingredients all year round. Whether you need to purchase in
        bulk or prefer to buy retail quantities, we have the flexibility to meet
        your needs.
      </span>
      <LabelDiv>
        <h4>
          MEETING THE NEEDS <span>OF INDEPENDENT MARKETS</span>
        </h4>
      </LabelDiv>
      <span>
        At Best Catering, we also understand the key ingredients needed for your
        independent retail businesses to thrive. That's why our focus is on
        providing you with great value, efficient service, and consistent
        quality. With more than 3,000 branded and own-brand products, covering
        fresh, chilled, frozen, ambient, and non-food items, you can find
        everything you need under one roof.
      </span>
      <LabelDiv>
        <h4>AVAILABLE PRODUCTS</h4>
      </LabelDiv>
      <span>
        From fresh fruits and vegetables to branded crisps, snacks, soft drinks,
        and general groceries, we cover all your convenience retail needs in one
        place. Our value brands to provide you with quality and consistency that
        you will only find at Best Catering.
      </span>
      <br />
      <span>
        At Best Catering, we aim to keep customer satisfaction at the highest
        level by offering high-quality products at affordable prices. By working
        with us, you can source all your fresh produce and grocery needs from a
        reliable supplier and provide the best service to your customers.
      </span>
      <br />
      <span>
        <strong>
          {" "}
          ***Delivery days can differ based on where you are located.
        </strong>
      </span>
    </EachFeature>
  );
}

export default IndependentMarketSectorComponent;
