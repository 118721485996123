import styled from "styled-components";
// #region Style
const EachFeature = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  width: 100%;
  @media only screen and (max-width: 768px) {
    margin: 1rem 1rem;
  }
  & span {
    color: #212b36;
    text-align: left;
    font-family: "Roboto";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4rem;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 0.9rem;
    }
    & span {
      color: #1aa6e1;
    }
  }
  & h3 {
    color: #5f5f5f;
    text-align: left;
    font-family: "Roboto";
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 0.9rem;
    }
  }
  & h1 {
    color: #212b36;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    @media only screen and (max-width: 968px) {
      font-weight: 500;
      font-size: 1.5rem;
    }
  }
  & h2 {
    color: #1aa6e1;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    margin-left: 0.5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 500;
      font-size: 1.5rem;
    }
  }
  & h4 {
    color: #212b36;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    @media only screen and (max-width: 968px) {
      font-weight: 450;
      font-size: 1.5rem;
    }
    & span {
      color: #1aa6e1;

      text-align: left;
      font-family: "Roboto Slab";
      font-size: 2.18rem;
      font-style: normal;
      font-weight: 450;
      line-height: normal;
      @media only screen and (max-width: 968px) {
        font-weight: 450;
        font-size: 1.5rem;
      }
    }
  }
  & h5 {
    color: #1aa6e1;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    margin-left: 0.5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 450;
      font-size: 1.5rem;
    }
  }
  &:hover {
    opacity: 1;
  }
`;
const LabelDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const EachCategoryImage = styled.img`
  width: 100%;
  object-fit: contain;
  margin-bottom: 1.2rem;
`;
// #endregion
function HospitalSectorComponent() {
  return (
    <EachFeature>
      <LabelDiv>
        <h4>
          <span>HOSPITAL AND CARE HOME CATERING </span>: DEPENDABLE WHOLESALE
          SOLUTIONS FOR LONDON FOOD SERVICES{" "}
        </h4>
      </LabelDiv>

      <h3>
        Exceptional Quality, Value, and Service for All Your Fresh Produce and
        Grocery Needs
      </h3>
      <EachCategoryImage
        src={"/catalog/ShopBySector/hospital.png"}
      ></EachCategoryImage>
      <span>
        At Best Catering, we can meet the A to Z needs of healthcare caterers
        with our comprehensive range of products and services. With over 15
        years of experience in the industry, we provide excellent value,
        top-quality food, and reliable service for various food service
        establishments throughout London. Whether you operate a care facility,
        hospital, or other healthcare setting, our extensive product lineup
        ensures you have everything required to support your operations
        effectively.
      </span>
      <LabelDiv>
        <h4>
          WHAT DO WE <span>PROVIDE</span>
        </h4>
      </LabelDiv>
      <br />
      <span>
        We understand that healthcare settings need a diverse selection of
        products to deliver balanced and nutritious meals. Our extensive
        inventory includes over 3,000 items, covering every meal occasion from
        breakfast to dinner and everything in between. Here’s a glimpse of what
        we offer:
      </span>
      <br />
       <span>
        <span>Fresh Produce: </span>
        We provide a wide variety of fresh fruits and vegetables, including
        multiple types of tomatoes, seasonal produce, and bulk items like 10kg
        sacks of onions and potatoes. Our commitment to freshness guarantees
        that your meals are always vibrant and nutritious.
      </span>
      <span>
        <span>Chilled Goods: </span>
        Our selection features premium dairy products such as cheese, milk, and
        yogurt, as well as high-quality meats and poultry, perfect for creating
        wholesome and delicious meals.
      </span>
      <span>
        <span>Frozen Foods: </span>
        We offer an array of frozen items, including fruits, vegetables, and
        ready-to-cook meals, ensuring convenience without compromising quality.
      </span>
      <span>
        <span>Ambient Products: </span>
        Stock up on essential groceries, including snacks, crisps, soft drinks,
        and pantry staples, to meet your everyday needs.
      </span>
      <span>
        <span>Non-Food Items: </span>
        Keep your facility spotless with our professional-grade cleaning
        products and packaging solutions.
      </span>
      <br />
      <span>
        At Best Catering, we focus on delivering the highest standards of
        quality and reliability across all our products. Whether you need to
        order in bulk or prefer smaller quantities, our flexible purchasing
        options are designed to suit your specific needs. Our user-friendly
        online ordering system allows for same-day collection or next-day
        delivery, ensuring you receive your supplies promptly and efficiently.
      </span>
      <br />
      <span>
        <strong>
          {" "}
          ***Delivery days can differ based on where you are located.
        </strong>
      </span>
    </EachFeature>
  );
}

export default HospitalSectorComponent;
