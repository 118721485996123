import React, { useEffect } from "react";
import styled from "styled-components";
import { changeAndAddMetaTags } from "../utilities/helpers";
import TermsAndConditionsContent from "../components/PagesComponents/TermsAndConditionsContent";
const Container = styled.div`
  width: 100%;
  max-width: 1280px;
  padding: 0.2rem;
`;

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  useEffect(() => {
    changeAndAddMetaTags(
      "Terms & Conditions | Best Catering Food Service Online London",
      "Terms & Conditions | Best Catering Food Service Online London",
      document.URL
    );
  }, []);

  return (
    <Container>
      <TermsAndConditionsContent />
    </Container>
  );
};

export default TermsAndConditions;
