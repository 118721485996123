import styled from "styled-components";
import CardContent from "../components/PagesComponents/CardContent";
import {useNavigate, useParams} from "react-router-dom";
import { useState,useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as productActions from "../redux/actions/productActions";
import * as userActions from "../redux/actions/userActions";
import * as settingsActions from "../redux/actions/settingsActions";
import CircularProgress from '@mui/material/CircularProgress';
import useWindowWidthAndHeight from "../utilities/hooks/useWindowWidthAndHeight";
import NoFound from "./NoFound";
import { changeAndAddMetaTags } from "../utilities/helpers";
import ErrorBoundaryWrapper from "../utilities/ErrorBoundary";
const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  max-width: 1280px;
`;
const ContainerLoading = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 2rem 0.5rem;
  align-items: center;
  min-height: 40vw;
`;
function Card(props) {

    const [loading,setLoading]=useState(true)
    const [noProduct,setNoProduct]=useState(false)
    const [currentProduct,setCurrentProduct]=useState({})
    let params = useParams();
    const navigate = useNavigate();
  useEffect(()=>{
    loading && window.scrollTo({top: 0,left: 0,behavior: "smooth",}); 
    const getProduct = async () => {
      if(params.productid){
        const elements = params.productid.split('-')
        if(elements.length > 0) {
          const id = elements[elements.length-1]
          let url = `${process.env.REACT_APP_API}/products/card/${id}?customerprice=${props.currentUser.customerpricegroup}&customerno=${props.currentUser.customerno}`
          await fetch(url)
            .then((res) => res.json())
            .then((res) => {
              if(res.message.data.length > 0) {
                setCurrentProduct(res.message.data[0]);
                setLoading(false);
              }else{
                setNoProduct(true);
                setLoading(false);
                
              } 
            });
        }else{
          setNoProduct(true);
          setLoading(false);
          
        }
      }else{
        setNoProduct(true);
        setLoading(false);
        
      }
    }
    getProduct()  
  },[params.productid])
  return (
    <Container>
      {(!loading) ? 
          (noProduct) ? 
          <NoFound/> 
          :(
            <>
              {changeAndAddMetaTags(`Buy ${currentProduct.seodescription}  with best price and collection or delivery options from`,`Buy ${currentProduct.seodescription} | Best Catering Food Service Online London`,document.URL)}
              <ErrorBoundaryWrapper navigate={navigate}>
                <CardContent key={currentProduct.product_id} product={currentProduct} />
              </ErrorBoundaryWrapper>
            </>
            
          )
          
        :
        <ContainerLoading>
          <CircularProgress size={40} thickness={4}/>
        </ContainerLoading>
      }
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    allProducts: state.allProductListReducer,
    currentUser: state.currentUserReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getAllProducts: bindActionCreators(
        productActions.getSearchProducts,
        dispatch
      ),
      getUser : bindActionCreators(userActions.getUser,dispatch),
      getSettings:bindActionCreators(settingsActions.getSettings,dispatch)
    },
  };
}
export default connect(mapStateToProps,mapDispatchToProps)(Card);
