import styled from "styled-components";
// #region Style
const EachFeature = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  width: 100%;
  @media only screen and (max-width: 768px) {
    margin: 1rem 1rem;
  }
  & span {
    color: #212b36;
    text-align: left;
    font-family: "Roboto";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4rem;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 0.9rem;
    }
  }
  & h3 {
    color: #5f5f5f;
    text-align: left;
    font-family: "Roboto";
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 0.9rem;
    }
  }
  & h1 {
    color: #212b36;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    @media only screen and (max-width: 968px) {
      font-weight: 500;
      font-size: 1.5rem;
    }
  }
  & h2 {
    color: #1aa6e1;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    margin-left: 0.5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 500;
      font-size: 1.5rem;
    }
  }
  & h4 {
    color: #212b36;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    @media only screen and (max-width: 968px) {
      font-weight: 450;
      font-size: 1.5rem;
    }
    & span {
      color: #1aa6e1;

      text-align: left;
      font-family: "Roboto Slab";
      font-size: 2.18rem;
      font-style: normal;
      font-weight: 450;
      line-height: normal;
      @media only screen and (max-width: 968px) {
        font-weight: 450;
        font-size: 1.5rem;
      }
    }
  }
  & h5 {
    color: #1aa6e1;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    margin-left: 0.5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 450;
      font-size: 1.5rem;
    }
  }
  &:hover {
    opacity: 1;
  }
`;
const LabelDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const EachCategoryImage = styled.img`
  width: 100%;
  object-fit: contain;
  margin-bottom: 1.2rem;
  
`;
// #endregion
function HotelsSectorComponent() {
  return (
    <EachFeature>
      <LabelDiv>
        <h4>YOUR ULTIMATE PARTNER FOR <span>HOTEL CATERING AND SUPPLIES</span></h4>
      </LabelDiv>

      <h3>Your Trusted Partner For All Catering Needs</h3>
      <EachCategoryImage
        src={"/catalog/ShopBySector/hotels.png"}
      ></EachCategoryImage>
      <span>
        For over 15 years, Best Catering has been a reliable supplier to hotels
        across the UK. All the products you need for your breakfast menus are
        available at Best Catering, and we also offer a wide range of cleaning
        supplies, with quick and easy online ordering. Our commitment is to
        provide consistent quality, reliable service, and all your needs in one
        place. Choose from our extensive range of over 2000 carefully selected
        products across food, drink, and non-food categories.
      </span>
      <LabelDiv>
        <h4>WHAT WE <span>PROVIDE</span></h4>
      </LabelDiv>
      <span>
        Whether you're setting up a breakfast buffet, providing lunchtime
        snacks, or creating a gourmet à la carte menu, we offer premium
        products, including meats, fruits, and vegetables from top-quality
        suppliers. Our range meets various dietary needs, including halal
        options, and features sustainably sourced ingredients. From tender
        chicken steaks and beef sausages to succulent king prawns and delightful
        sweets, and a variety of snacks, we have everything you need.{" "}
      </span>
      <LabelDiv>
        <h4>WHY WE'RE YOUR GO-TO<span> FOOD SERVICE PROVIDER</span></h4>
      </LabelDiv>
      <span>Here’s what makes us your trusted choice:</span>
      <span>
        ✓ Comprehensive Supply - Everything you need for your hospitality needs
        in one place.
      </span>
      <span>
        ✓ Exceptional Value - Enjoy both well-known brands and our high-quality
        own brands.
      </span>
      <span>
        ✓ Hassle-Free Ordering - Convenient online ordering through our
        intuitive app.
      </span>
      <br />
      <span>
        <strong>
          {" "}
          ***Delivery days can differ based on where you are located.
        </strong>
      </span>
    </EachFeature>
  );
}

export default HotelsSectorComponent;
