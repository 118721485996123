import styled from "styled-components";
import { useState,useEffect } from "react";
import { useNavigate,useSearchParams } from "react-router-dom";
import {colors} from "../../res/values/values"
import { ArrowBack } from "@material-ui/icons";
//#region styles
const Accordion = styled.div`
   max-width: 600px; 
  font-size: 1.1rem;
  letter-spacing: 1.5px;
  font-weight: 300;
  margin-bottom: 5px;
  margin-top: 1rem;
  border: 1px solid lightgray;
`;
const AccordionDiv = styled.div`
  max-height:35vw ;
  overflow-y: auto;
  white-space: nowrap;
  @media only screen and (max-width: 600px) {
    max-height:50vw ;
  }
`;
const MainItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  padding: 1rem;
  color:  ${colors.primaryColor};
  font-size: 1.1rem;
  font-weight:800 ;
  letter-spacing: 1px;
  overflow-wrap: anywhere;
  & h1{
    font-size: 1.1rem;
    font-weight:800 ;
    letter-spacing: 1px;
  }
  & h2{
    font-size: 1rem;
    font-weight:700 ;
    letter-spacing: 1px;
  }
  &:hover {
    background-color: ${colors.primaryColor};
    color:${colors.whiteColor}
  }
`;
const SubItem = styled.div`
  padding: 15px 20px;
  color: #333;
  font-size: 0.9rem;
  font-weight:700 ;
  letter-spacing: 1px;
  cursor: pointer;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  &:hover {
    background-color: ${colors.primaryColor};
    color:${colors.whiteColor}
  }
`;
const Return = styled.div`
  background-color:  ${colors.whiteColor};
  padding: 8px 10px;
  color: ${colors.primaryColor};
  font-size: 0.9rem;
  border-width: 0 0px 1px 0px;
  border-color: lightgray;
  border-style: solid;
  cursor: pointer;
  &:hover {
    background-color: ${colors.primaryColor};
    color:${colors.whiteColor}
  }
`;
const SelectSubItem = styled.div`
  padding: 15px 20px;
  font-size: 0.9rem;
  font-weight:700 ;
  letter-spacing: 1px;
  background-color: ${colors.primaryColor};
  color:${colors.whiteColor};
  border-width: 0 0px 1px 0px;
  border-color: lightgray;
  border-style: solid;
  cursor: pointer;
  overflow-wrap: anywhere;
`;
//#endregion
function AccordionMenu(props) {
  const navigate = useNavigate()
  const [isActive, setIsActive] = useState(false);

  const data = props.item;
  useEffect(()=>{
    
  },[])
  
  const changeIsActiveStatus =() =>{
    setIsActive(!isActive) 
  }
  const selectedSubItem = (item)=>{
    if(data.type==="ProductGroup"){
      (item.code === props.productid || item.code === props.brand || item.code === props.country) ? 
      navigate(`/product-categories/${item.seodescription}?${props.filter ? `&filter=${props.filter}` : ''}${props.brand ? `&brand=${props.brand}` : ''}${props.country ? `&country=${props.country}` : ''}`) :
      navigate(`/product-categories/${item.seodescription}?${props.filter ? `&filter=${props.filter}` : ''}${props.brand ? `&brand=${props.brand}` : ''}${props.country ? `&country=${props.country}` : ''}`)

    }else if(data.type==="Brands"){
      (item.code === props.productid || item.code === props.brand || item.name === props.country)
      ?
      navigate(`/search?${props.filter ? `&filter=${props.filter}` : ''}${props.categoryid ? `&categoryid=${props.categoryid}` : ''}${props.productid ? `&productid=${props.productid}` : ''}${props.country ? `&country=${props.country}` : ''}`) 
      :
      navigate(`/search?${props.filter ? `&filter=${props.filter}` : ''}${props.categoryid ? `&categoryid=${props.categoryid}` : ''}${props.productid ? `&productid=${props.productid}` : ''}&brand=${item.code}${props.country ? `&country=${props.country}` : ''}`) 
    }else if(data.type==="Country"){
      (item.code === props.productid || item.code === props.brand || item.name === props.country)
      ?
      navigate(`/search?${props.filter ? `&filter=${props.filter}` : ''}${props.categoryid ? `&categoryid=${props.categoryid}` : ''}${props.productid ? `&productid=${props.productid}` : ''}${props.brand ? `&brand=${props.brand}` : ''}`) 
      :
      navigate(`/search?${props.filter ? `&filter=${props.filter}` : ''}${props.categoryid ? `&categoryid=${props.categoryid}` : ''}${props.productid ? `&productid=${props.productid}` : ''}${props.brand ? `&brand=${props.brand}` : ''}&country=${item.name}`) 
    }
    else if(data.type==="Category"){
      navigate(`/product-categories/${item.seodescription}?${props.filter ? `&filter=${props.filter}` : ''}${props.brand ? `&brand=${props.brand}` : ''}${props.country ? `&country=${props.country}` : ''}`) 
    }
    
  }
  return (
    <Accordion>
      <MainItem onClick={() => changeIsActiveStatus()}>
        {(data.type === 'ProductGroup' || data.type === 'SubProductGroup') ? <h1>{data.title}</h1> : <h2>{data.title}</h2>}
        <div>{isActive ? "-" : "+"}</div>
      </MainItem>
      <AccordionDiv>
        {isActive &&
          data.content.map((value) => {
            if( value.code === props.productid || value.code === props.brand || value.name===props.country){
              return <SelectSubItem onClick={()=>{selectedSubItem(value)}} key={value.code}>{(data.type==='Brands' || data.type==='Country') ? value.name: value.description}</SelectSubItem>;
            }
            else
              return <SubItem onClick={()=>{selectedSubItem(value)}} key={value.code}>{(data.type==='Brands' || data.type==='Country') ? value.name: value.description}</SubItem>;
          })
        }
        
      </AccordionDiv>
      
    </Accordion>
  );
}

export default AccordionMenu;
//{(data.type==="ProductGroup" && isActive) && <Return onClick={()=>{returnPreviousCategory()}}> RETURN TO PREVIOUS CATEGORIES</Return>}