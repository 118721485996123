//003 ++ Check Out Page Payment Method Kotrolünün Eklenmesi.

import * as React from "react";
import { useState } from "react";
import FormControl from "@mui/material/FormControl";
import { Checkbox } from "@mui/material";
import styled from "styled-components";
import { colors } from "../../res/values/values";

//#region STYLES

const Container = styled.div`
  min-height: 10rem;
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: lightgray;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
`;
const Button = styled.div`
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 1px;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  background-color: ${colors.primaryColor};
  border-color: #dddddd #dddddd #b3b3b3 #b7b7b7;
  color: #fff;
  border-radius: 2px;
  margin: 2px;
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.darkcolor};
  }
`;

const Title = styled.span`
  font-size: 1rem;
  color: ${colors.primaryColor};
  font-weight: 500;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
  flex: 3;
`;
const Agreement = styled.span`
  width: 100%;
  font-size: 14px;
  margin: 20px 0;
`;
//#endregion

export default function PaymentMethodComponent(props) {
  const [check, setCheck] = useState(props.cashOnDelivery);
  return (
    <Container>
      <FormControl>
        <Title>
          Please select the preferred payment method to use on this order.
        </Title>
        <Agreement>
          Cash On Delivery
          <Checkbox
            sx={{
              color: colors.primaryColor,
              "&.Mui-checked": {
                color: colors.primaryColor,
              },
            }}
            onClick={() => {
              if (props.currentUser.pepecustomer) {
                props.setCashOnDelivery(!check);
                setCheck(!check);
              }else {
                  
              }
              
            }}
            value={check}
          />
        </Agreement>
      </FormControl>

      <ButtonContainer>
        <Button
          onClick={() => {
            props.changeOpenStatus(true);
          }}
        >
          Continue
        </Button>
      </ButtonContainer>
    </Container>
  );
}
