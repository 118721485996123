import styled from "styled-components";
import ProductCardView from "./ProductCardView";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as productActions from "../../redux/actions/productActions";
import { colors } from "../../res/values/values";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from "react-router-dom";

//#region Styles
const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0rem 0.3rem;
  align-items: center;
  min-height: 40vw;
`;
const ProductList = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
  }
`;
const EmptyProductList = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: ${colors.primaryColor};
`;
const TitleContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  
`;
const Title = styled.span`
  width: auto;
  margin-top: 20px;
  //margin-bottom: 20px;
  font-size: 1.2rem;
  font-weight: 500;
  color: ${colors.primaryColor};
  padding-left: 10px;

`;
const TitleViewAll = styled.span`
  width: auto;
  margin-top: 20px;
  //margin-bottom: 20px;
  font-size: 1.2rem;
  font-weight: 500;
  color: ${colors.primaryColor};
  padding-right: 2rem;
  &:hover {
    //background-color: ${colors.primaryColor};
    color:  ${colors.darkcolor};
    cursor: pointer;
  }
`;
const Divider = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #cacaca;
`;


//#endregion

function HomeWebOfferProducts(props) {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(parseInt(props.page));
  const [rowsPerPage, setRowsPerPage] = useState(parseInt(props.rowsPerPage));
  const [listType, setListType] = useState(props.listType);
  const navigate = useNavigate();
  useEffect(() => {

    const renderPage = async () => {
      
     // if (changePageStatus) setChangePageStatus(false);
     // else {
     //   setPage(0);
     //   setRowsPerPage(10);
     // }
      await props.actions.getProducts(
        props.categoryid,
        props.productid,
        props.filter,
        page,
        rowsPerPage,
        props.currentUser,
        props.brand,
        props.country
      );

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      setLoading(false);
    };
    //setLoading(true)
    renderPage();
    return () => {
      //setLoading(true);
      //props.actions.clearProducts();
    };
  }, [
    props.filter,
    props.productid,
    props.categoryid,
    props.brand,
    props.country,
    props.page,
    props.rowsPerPage,
    props.listType
  ]);

  return (
    <Container>
      {loading ? (
        <Container>
          <CircularProgress
                  size={40}
                  thickness={4}
            
                />
        </Container>
      ) : props.products.length > 0 ? (
        <Container>
            <TitleContent>
          <Title>
            {"Web Offers"}{" "}
          </Title>
          <TitleViewAll onClick={() =>
                navigate("/search?filter=weboffers")
            }>
            {" View All ... "}
          </TitleViewAll>
        </TitleContent>
          
          <Divider />
          <ProductList>
            {props.products.map((p) => {
              return (
                <ProductCardView
                  key={p.itemno}
                  product={p}
                  viewType={listType}
                  page={page}
                  rowsPerPage={rowsPerPage}
                />
              );
              /*
              if(listType==='Grid')
                return( <ProductCard key={p.itemno} product={p} />);
              else
                return( <ProductCardForListView key={p.itemno} product={p} />);
              */
            })}
          </ProductList>
        </Container>
      ) : (
        <EmptyProductList>
          Sorry, there were no results matching your search or filter.
        </EmptyProductList>
      )}
    </Container>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getProducts: bindActionCreators(productActions.getProducts, dispatch),
      getFilterProducts: bindActionCreators(
        productActions.getFilterProducts,
        dispatch
      ),
      clearProducts: bindActionCreators(
        productActions.clearProductsSuccess,
        dispatch
      ),
    },
  };
}

function mapStateToProps(state) {
  return {
    products: state.productListReducer,
    search: state.changeSearchReducer,
    productCount: state.productCountReducer,
    currentUser: state.currentUserReducer,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(HomeWebOfferProducts);
