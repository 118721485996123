import React from "react";
import styled from "styled-components";
import BrandsContent from "../components/PagesComponents/BrandsContent";
const Container = styled.div`
  max-width: 1280px;
  //width: ${(props) => props.width}px;
`;
const Brands = () => {
  return (
    <Container>
      <BrandsContent />
    </Container>
  );
};

export default Brands;
