import styled from "styled-components";
import { useState } from "react";
import {colors} from "../../res/values/values"
import MobileMenuBarContent from "../MenuComponents/MobileMenuBarContent";
import { capitalizeFirstLetter } from "../../utilities/helpers";
//#region styles
const Container = styled.div`
`;
const MenuItem = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.primaryColor};
  font-family: 'Roboto';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  & a {
    text-decoration: ${(props) => (props.menuToggle ? "underline" : "none")};
    color: ${(props) => (props.menuToggle ? colors.secondryColor : "#212b36")};
    display:flex !important;
    justify-content: center;
    align-items: center;
  }
  & :hover {
    //font-weight: 500;
  }
  & p {
    margin-bottom: 0rem;
  }
`;
const MenuItemContent = styled.div`
  position: absolute;
  background-color: white;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
  top: 2rem;
  z-index: 9997;
  min-height: 40vh;
  max-height:75vh ;
  overflow-y: scroll;
  white-space: nowrap;
  color: ${colors.primaryColor};
  overflow: hidden;  

  
  & a {
    color: ${colors.primaryColor};
    padding: 8px 10px;
    text-decoration: none;
    display: block;
    //border-bottom: 1px solid #e5e5e5;
  }
  & a:hover {
     //background-color: ${colors.whiteColor}; 
  }
`;
const IconImage = styled.img`
height: 1rem;
width: 1rem;
min-height: 1rem;
object-fit: contain;
margin-left: .3rem;
`;
//#endregion
const DropDownMenu = (props) => {
  const [menuToggle, setmenuToggle] = useState(false);
  const onMouseClickHandler = () => {
    setmenuToggle(true)
  };
  const onMouseEnterHandler = () => {
    setmenuToggle(true)
  };
  const onMouseLeaveHandler = () => {
    setmenuToggle(false);
  };
  return (
    <Container>
      <MenuItem menuToggle={menuToggle}
        
        onMouseOver={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        //onClick={onMouseClickHandler}
      >
        {props.icon !== undefined && props.icon}
        {props.category ? <a
                        href={"/product-categories/" + props.category.seodescription}
                        onClick={(event) => props.onClick(event, props.category)}
                        title={props.category.seodescription}
                      >
                        {props.text}
                        <IconImage src={`./images/down.png`} />
                        
                      </a> : <p>{props.text}</p>}

      </MenuItem>
      <MenuItemContent
        onMouseOver={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        //onClick={onMouseLeaveHandler}
        style={{
          width: props.contentWidth,
          display: menuToggle ? "block" : "none",
          right: props.right !== undefined ? props.right : "",
          left: props.left !== undefined ? props.left : "",
          top: props.top !== undefined ? props.top : "",
          minHeight: props.minHeight !== undefined ? props.minHeight : "",
          
        }}
      >
        {
          (props.contentLinks==="mobileMenu") ?
          <MobileMenuBarContent close={onMouseLeaveHandler} setOpenStoreLocation={props.setOpenStoreLocation}/>
          :
          props.contentLinks
        }
      </MenuItemContent>
    </Container>
  );
};

export default DropDownMenu;
