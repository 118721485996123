import { useEffect } from "react";
import styled from "styled-components";
import ShoppingCartContent from "../components/PagesComponents/ShoppingCartContent";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as settingsActions from "../redux/actions/settingsActions";
const Container = styled.div`
  display:flex ;
  flex:1 ;
  flex-direction:column ;

  justify-content:space-between ;
  max-width: 1280px;
`;
function ShoppingCart(props) {

  useEffect(()=>{
    props.actions.getSettings()
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },[])
  return (
    <Container >
      <ShoppingCartContent/>
    </Container>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getSettings:bindActionCreators(settingsActions.getSettings,dispatch)
    },
  };
}
export default connect(null,mapDispatchToProps)(ShoppingCart) ;
