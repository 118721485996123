import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { colors } from "../../res/values/values";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as manufacturerActions from "../../redux/actions/manufacturerActions";
import { useNavigate } from "react-router-dom";

//#region Styles
const BodyDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 40px 10px;
`;
const Glide = styled.div`
   display:flex ;
  width: 23%;
  height: 10rem;
  transition: 0.5s;
  justify-content:center ;
  align-items:center ;
  cursor:pointer;
  //border: 1px solid #f2f2f2;
  @media only screen and (max-width: 480px) {
    width: 92%;
  }
  @media only screen and (min-width: 480px) and (max-width: 720px) {
    width: 46%;
  }
`;
const Poster = styled.img`
  width: 90%;
  height: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-fit: scale-down;
  opacity: 0.8;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;
const BrandName = styled.span`
  width: auto;
  font-size: 1.2rem;
  font-weight: 700;
  color: ${colors.primaryColor};
`;
//#endregion
function BrandList(props) {
  const navigate = useNavigate();
  useEffect(() => {
    const getBrands = async () => {
      if (props.manufacturers.length === 0) await props.actions.getBrands(); 
    };
    getBrands();
  }, [ props.manufacturers]);
  const rootBrands = (brand) => {
    navigate(`/search?${brand ? `brand=${brand}` : ""}`)
  };
  return (
    <BodyDiv>
      {props.manufacturers.length > 0 &&
        props.manufacturers.map((brand, index) => {
              return (
                <Glide key={index} onClick={()=>rootBrands(brand.code)}>
                  {brand.image ? 
                  <Poster
                    key={brand.code}
                    className="poster"
                    src={`./catalog/Brands/${brand.code}.jpg`}
                  />:<BrandName>{brand.name}</BrandName>}
                </Glide>
              );
            
            //if(loading && props.manufacturers.length === index+1) setLoading(false);
        })}
    </BodyDiv>
  );
}
function mapStateToProps(state) {
  return {
    manufacturers: state.manufacturerReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getBrands: bindActionCreators(
        manufacturerActions.getManufacturerList,
        dispatch
      ),
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(BrandList);
