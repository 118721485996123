/* eslint-disable react-hooks/exhaustive-deps */
import styled from "styled-components";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as userActions from "../../redux/actions/userActions";
import * as settingsActions from "../../redux/actions/settingsActions";
import * as cartActions from "../../redux/actions/cartActions";
import CheckOutAccordionMenu from "./CheckOutAccordionMenu";
import ConfirmOrderComponent from "./ConfirmOrderComponent";
import DeliveryDetailsComponent from "./DeliveryDetailsComponent";
import DeliveryMethodComponents from "./DeliveryMethodComponents";
//003 ++ Check Out Page Payment Method Kotrolünün Eklenmesi.
import PaymentMethodComponent from "./PaymentMethodComponent";
//003 -- Check Out Page Payment Method Kotrolünün Eklenmesi.
//004 ++ Check Out Page Payment Method Kotrolünün Eklenmesi.
import OrderCommentComponent from "./OrderCommentComponent";
//004 -- Check Out Page Payment Method Kotrolünün Eklenmesi.
import OrcaModal from "../GlobalComponents/OrcaModal";
import Charge from "../../pages/Charge";
import { colors } from "../../res/values/values";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

import DatePicker, { registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import en from "date-fns/locale/en-GB";
import { format, addDays, subDays, addWeeks, subWeeks } from "date-fns";
import { forwardRef } from "react";
registerLocale("en", en);

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

//#region STYLES
const Container = styled.div`
  min-height: 40vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 30px;
`;
const Error = styled.div`
  margin: 5px;
  width: 88.5%;
  padding: 10px;
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  font-size: 16px;
  font-weight: 300;
`;
const CurrentTime = styled.div`
  margin-bottom: 8px;
  color: ${colors.primaryColor};
  font-size: 16px;
  font-weight: 700;
  text-align: center;
`;
//#endregion

function CheckOutContent(props) {
  const navigate = useNavigate();
  const [deliveryDetailsOpenStatus, setDeliveryDetailsOpenStatus] = useState(true);
  //003 ++ Check Out Page Payment Method Kotrolünün Eklenmesi. 
  const [paymentMethodOpenStatus, setPaymentMethodOpenStatus] = useState(false);
  const [cashOnDelivery,setCashOnDelivery] = useState(false)
  //003 -- Check Out Page Payment Method Kotrolünün Eklenmesi.
  //004 ++  
  const [orderCommentOpenStatus, setOrderCommentOpenStatus] = useState(false);
  const [orderComment,setOrderComment] = useState('')
  //004 --
  const [deliveryMethodOpenStatus, setDeliveryMethodOpenStatus] = useState(false);
  const [confirmOrderOpenStatus, setConfirmOrderOpenStatus] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [deliveryMethod, setDeliveryMethod] = useState("");
  const [checkOutError, setCheckOutError] = useState("");

  const [loading, setLoading] = useState(true);

  const [colDelDate, SetColDelDate] = useState(new Date());
  const [visibleDatePicker, setVisibleDatePicker] = useState(false);
  const [delDayList, setDelDayList] = useState(new Date());
  const [firstDeliveryDate, setFirstDeliveryDate] = useState(null);
  const [deliveryError, setDeliveryError] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    async function getAPI() {
      await props.actions.getSettings();
      props.cart.length > 0
        ? setDeliveryMethod(props.cart[0].deliverymethod)
        : setDeliveryMethod("");
      const access_token = localStorage.getItem("access_token");
      if (access_token) await props.actions.getUser();
      await setDeliveryDayList(props.cart[0].deliverymethod);
      setLoading(false);
    }
    getAPI();
    return () => {
      //alert('DEAD')
    };
  }, []);

  const continueDeliveryDetails = (status) => {
    setDeliveryDetailsOpenStatus(!status);
    setPaymentMethodOpenStatus(status);
  };
  //003 ++ Check Out Page Payment Method Kotrolünün Eklenmesi. 
  const continuePaymetMethod = (status) => {
    //if(!cashOnDelivery && deliveryMethod==='Delivery' ){
     // setAlertMessage('In order to continue, you must choose cash on delivery because your delivery method selection is delivery !')
      //setDeliveryError(true)
    //}else{
      setPaymentMethodOpenStatus(!status);
      setDeliveryMethodOpenStatus(status);
      setVisibleDatePicker(true);
    //}
    
  };
  //003 ++ Check Out Page Payment Method Kotrolünün Eklenmesi. 
  const continueDeliveryMethod = (status) => {
    setDeliveryMethodOpenStatus(!status);
    //004 ++
    setOrderCommentOpenStatus(status);
    //setConfirmOrderOpenStatus(status);
    //004 --
  };
  //004 ++
  const continueOrderComment = (status) => {
    orderComment === '' && setOrderCommentOpenStatus(!status);
    setConfirmOrderOpenStatus(status);
  };
  //004 -- 
  const changeDeliveryMethod = async (deliverymethod) => {
    if (
      !props.currentUser.deliveryDates[0].monday &&
      !props.currentUser.deliveryDates[0].tuesday &&
      !props.currentUser.deliveryDates[0].wednesday &&
      !props.currentUser.deliveryDates[0].thursday &&
      !props.currentUser.deliveryDates[0].friday &&
      !props.currentUser.deliveryDates[0].saturday &&
      !props.currentUser.deliveryDates[0].sunday &&
      deliverymethod === "Delivery"
    ){
      setDeliveryError(true)
      setAlertMessage('Your delivery days is unknown !')
    }
     
    else {
      if(!cashOnDelivery && deliverymethod==='Delivery'){
        setAlertMessage('In order to continue, you must choose cash on delivery because your delivery method selection is delivery !')
        setDeliveryError(true)
        setPaymentMethodOpenStatus(true)
        setDeliveryMethodOpenStatus(false)
      }else{
        await props.actions.updateCartDeliveryMethod(deliverymethod);
        setDeliveryMethod(deliverymethod)
        if (deliverymethod === "Collection") {
          SetColDelDate(new Date());
          setVisibleDatePicker(true);
        } else {
          SetColDelDate(firstDeliveryDate);
          setVisibleDatePicker(true);
        }
      }
      
    }
  };
  const getTotalAmount = () => {
    let totalAmountInclVat = 0;
    props.cart.map((item) => (totalAmountInclVat += item.lineamountinclvat));
    return totalAmountInclVat.toFixed(2);
  };
  const confirmOrder = async () => {
    if (props.settings.payment && !cashOnDelivery) handleOpen();
    else {
      let user = {
        id: props.currentUser.id,
        customerno: props.currentUser.customerno,
        email: props.currentUser.email,
        address:
          selectedAddress !== ""
            ? selectedAddress
            : props.currentUser.adrressList[0].value,
        deliveryMethod: deliveryMethod,
        requestedCollectionDate:
          deliveryMethod === "Collection"
            ? format(colDelDate, "dd/MM/yyyy hh:mm")
            : "",
        requestedDeliveryDate: deliveryMethod === "Delivery" ? format(colDelDate, "dd/MM/yyyy 08:00") : "",
        //004++
        comment: orderComment,
        username:  props.currentUser.firstname +' '+ props.currentUser.lastname,
        companyname : props.currentUser.companyName,
        cardamount : 0,
        //004--
      };
      const access_token = localStorage.getItem("access_token");
      let url = `${process.env.REACT_APP_API}/cart/checkout`;
      await fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer: ${access_token}`,
        },
        body: JSON.stringify(user),
      })
        .then((res) => res.json())
        .then(async (res) => {
          if (res.success) await successPostOrder() //navigate("/route=account/order");
          else {
            setCheckOutError(res.message);
            if (
              res.message === "You are not authorization to access this route"
            ) {
              navigate("/account/login");
            }
          }
        })
        .catch((err) => {
          let error = JSON.parse(err.message);
        });
    }
  };
  const successPostOrder = async ()=>{
    await props.actions.getCartList()
    navigate("/account/order");
  }
  const confirmOrderWithPayment = async () => {
    let user = {
      id: props.currentUser.id,
      customerno: props.currentUser.customerno,
      email: props.currentUser.email,
      address:
        selectedAddress !== ""
          ? selectedAddress
          : props.currentUser.adrressList[0].value,
      deliveryMethod: deliveryMethod,
      requestedCollectionDate:
        deliveryMethod === "Collection" ? colDelDate : "",
      requestedDeliveryDate: deliveryMethod === "Delivery" ? format(colDelDate, "dd/MM/yyyy 08:00") : "",
      //004++
      comment: orderComment,
      username:  props.currentUser.firstname +' '+ props.currentUser.lastname,
      companyname : props.currentUser.companyName,
      cardamount : getTotalAmount(),
      //004--
    };
    const access_token = localStorage.getItem("access_token");
    let url = `${process.env.REACT_APP_API}/cart/checkout`;
    await fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer: ${access_token}`,
      },
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then(async (res) => {
        
        if (res.success) await successPostOrder() ;
        else {
          setCheckOutError(res.message);
          if (
            res.message === "You are not authorization to access this route"
          ) {
            navigate("/account/login");
          }
        }
      })
      .catch((err) => {
        let error = JSON.parse(err.message);
      });
    
  };
  const setDeliveryDayList = async (deliveryMethod) => {
    let firstday = null;
    let dayList = [];
    for (let i = 1; i < 120; i++) {
      let date = addDays(new Date(), i);
      let day = format(date, "EEEE");
      if(props.currentUser.deliveryDates.length > 0){
        // eslint-disable-next-line default-case
        switch (day) {
          case "Monday":
            if (props.currentUser.deliveryDates[0].monday) {
              if (!firstday) {
                firstday = date;
                setFirstDeliveryDate(date);
                deliveryMethod === "Delivery" && SetColDelDate(date);
              }
              dayList.push(date);
            }
            break;
          case "Tuesday":
            if (props.currentUser.deliveryDates[0].tuesday) {
              if (!firstday) {
                firstday = date;
                setFirstDeliveryDate(date);
                deliveryMethod === "Delivery" && SetColDelDate(date);
              }
              dayList.push(date);
            }
            break;
          case "Wednesday":
            if (props.currentUser.deliveryDates[0].wednesday) {
              if (!firstday) {
                firstday = date;
                setFirstDeliveryDate(date);
                deliveryMethod === "Delivery" && SetColDelDate(date);
              }
              dayList.push(date);
            }
            break;
          case "Thursday":
            if (props.currentUser.deliveryDates[0].thursday) {
              if (!firstday) {
                firstday = date;
                setFirstDeliveryDate(date);
                deliveryMethod === "Delivery" && SetColDelDate(date);
              }
              dayList.push(date);
            }
            break;
          case "Friday":
            if (props.currentUser.deliveryDates[0].friday) {
              if (!firstday) {
                firstday = date;
                setFirstDeliveryDate(date);
                deliveryMethod === "Delivery" && SetColDelDate(date);
              }
              dayList.push(date);
            }
            break;
          case "Saturday":
            if (props.currentUser.deliveryDates[0].saturday) {
              if (!firstday) {
                firstday = date;
                setFirstDeliveryDate(date);
                deliveryMethod === "Delivery" && SetColDelDate(date);
              }
              dayList.push(date);
            }
            break;
          case "Sunday":
            if (props.currentUser.deliveryDates[0].sunday) {
              if (!firstday) {
                firstday = date;
                setFirstDeliveryDate(date);
                deliveryMethod === "Delivery" && SetColDelDate(date);
              }
              dayList.push(date);
            }
            break;
        }
      }
      
      i === 119 && setDelDayList(dayList);
    }
  };
  return (
    <div>
      {!loading ? (
        <Container>
          {deliveryMethod === "Collection" ? (
            <OrcaModal isOpen={visibleDatePicker} onClose={handleClose}>
              <DatePicker
                open={visibleDatePicker}
                selected={colDelDate}
                onChange={(date) => SetColDelDate(date)}
                locale="en"
                inline
                showTimeInput
                minDate={new Date()}
                todayButton="today"
              />
              <button
                onClick={() => {
                  setVisibleDatePicker(false);
                }}
                style={{
                  backgroundColor: "white",
                  padding: 10,
                  width: "100%",
                  marginTop: 20,
                }}
              >
                OK
              </button>
            </OrcaModal>
          ) : (
            <OrcaModal isOpen={visibleDatePicker} onClose={handleClose}>
              <CurrentTime>
                {deliveryMethod === "Collection"
                  ? format(colDelDate, "dd/MM/yyyy HH:mm")
                  : format(colDelDate, "dd/MM/yyyy")}
              </CurrentTime>

              <DatePicker
                open={visibleDatePicker}
                selected={colDelDate}
                onChange={(date) => {
                  let day = format(date, "EEEE");
                  // eslint-disable-next-line default-case
                  switch (day) {
                    case "Monday":
                      if(props.currentUser.deliveryDates[0].monday) SetColDelDate(date)
                      else {
                        setDeliveryError(true)
                        setAlertMessage('It is not a valid delivery day. Please select a valid delivery day.')
                        }
      
                      break;
                    case "Tuesday":
                      if(props.currentUser.deliveryDates[0].tuesday) SetColDelDate(date)
                      else {
                        setDeliveryError(true)
                        setAlertMessage('It is not a valid delivery day. Please select a valid delivery day.')
                        }
                      break;
                    case "Wednesday":
                      if(props.currentUser.deliveryDates[0].wednesday) SetColDelDate(date)
                      else {
                        setDeliveryError(true)
                        setAlertMessage('It is not a valid delivery day. Please select a valid delivery day.')
                        }
                      break;
                    case "Thursday":
                      if(props.currentUser.deliveryDates[0].thursday) SetColDelDate(date)
                      else {
                        setDeliveryError(true)
                        setAlertMessage('It is not a valid delivery day. Please select a valid delivery day.')
                        }
                       
                      break;
                    case "Friday":
                     if(props.currentUser.deliveryDates[0].friday) SetColDelDate(date)
                     else {
                       setDeliveryError(true)
                       setAlertMessage('It is not a valid delivery day. Please select a valid delivery day.')
                       }
                      
                      break;
                    case "Saturday":
                      if(props.currentUser.deliveryDates[0].saturday) SetColDelDate(date)
                      else {
                        setDeliveryError(true)
                        setAlertMessage('It is not a valid delivery day. Please select a valid delivery day.')
                        }
                      
                      break;
                    case "Sunday":
                      if(props.currentUser.deliveryDates[0].sunday) SetColDelDate(date)
                      else {
                        setDeliveryError(true)
                        setAlertMessage('It is not a valid delivery day. Please select a valid delivery day.')
                        }
                    
                      break;
                  }
                }}
                locale="en"
                inline
                disabledKeyboardNavigation
                minDate={addDays(new Date(), 1)}
                maxDate={addDays(new Date(), 119)}
                highlightDates={delDayList}
              />
              <button
                onClick={() => {
                  setVisibleDatePicker(false);
                }}
                style={{
                  backgroundColor: "white",
                  padding: 10,
                  width: "100%",
                  marginTop: 20,
                }}
              >
                OK
              </button>
            </OrcaModal>
          )}

          <OrcaModal isOpen={open} onClose={handleClose}>
            <Charge
              totalPayment={getTotalAmount}
              confirmOrderWithPayment={confirmOrderWithPayment}
              user={{
                name: props.currentUser.firstname,
                email: props.currentUser.email,
                address:
                  selectedAddress !== ""
                    ? selectedAddress
                    : props.currentUser.adrressList[0].value,
              }}
            />
          </OrcaModal>
        
          {checkOutError !== "" && <Error>{checkOutError}</Error>}
          <CheckOutAccordionMenu
            isActive={deliveryDetailsOpenStatus}
            key={"COT1"}
            a={"COT1"}
            item={{
              title: "Delivery Details",
              content: (
                <DeliveryDetailsComponent
                  changeOpenStatus={continueDeliveryDetails}
                  dropDownList={
                    props.currentUser.adrressList
                      ? [...props.currentUser.adrressList]
                      : []
                  }
                  selectedAddress={selectedAddress}
                  setSelectedAddress={setSelectedAddress}
                />
              ),
            }}
          />
          <CheckOutAccordionMenu
            isActive={paymentMethodOpenStatus}
            key={"COT2"}
            a={"COT2"}
            item={{
              title: "Payment Method",
              content: (
                <PaymentMethodComponent
                  setCashOnDelivery={setCashOnDelivery}
                  cashOnDelivery={cashOnDelivery}
                  changeOpenStatus={continuePaymetMethod}
                  currentUser={props.currentUser}
                />
              ),
            }}
          />
          <CheckOutAccordionMenu
            isActive={deliveryMethodOpenStatus}
            key={"COT3"}
            a={"COT3"}
            item={{
              title: "Delivery Method",
              content: (
                <DeliveryMethodComponents
                  deliveryMethod={deliveryMethod}
                  setDeliveryMethod={setDeliveryMethod}
                  changeOpenStatus={continueDeliveryMethod}
                  changeDeliveryMethod={changeDeliveryMethod}
                  requestedDate={colDelDate}
                  setVisibleDatePicker={setVisibleDatePicker}
                  settings={props.settings}
                />
              ),
            }}
          />
          <CheckOutAccordionMenu
            isActive={orderCommentOpenStatus}
            key={"COT5"}
            a={"COT5"}
            item={{
              title: "Order Comment",
              content: (
                <OrderCommentComponent changeOrderComment={setOrderComment} p_comment={orderComment} changeOpenStatus={continueOrderComment}/>
              ),
            }}
          />
          <CheckOutAccordionMenu
            isActive={confirmOrderOpenStatus}
            key={"COT4"}
            a={"COT4"}
            item={{
              title: "Confirm Order",
              content: (
                <ConfirmOrderComponent confirmOrderClick={confirmOrder} />
              ),
            }}
          />
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar
              open={deliveryError}
              autoHideDuration={2000}
              onClose={() => setDeliveryError(false)}
            >
              <Alert
                onClose={() => setDeliveryError(false)}
                severity="error"
                sx={{ width: "100%", padding: "18px 16px" }}
              >
               {alertMessage}
              </Alert>
            </Snackbar>
          </Stack>
         
        </Container>
      ) : (
        <Container></Container>
      )}
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getUser: bindActionCreators(userActions.getUser, dispatch),
      getSettings: bindActionCreators(settingsActions.getSettings, dispatch),
      updateCartDeliveryMethod: bindActionCreators(
        cartActions.updateCartDeliveryMethod,
        dispatch
      ),
      getCartList: bindActionCreators(cartActions.getCartList, dispatch),
    },
  };
}
function mapStateToProps(state) {
  return {
    currentUser: state.currentUserReducer,
    cart: state.cartActionReducer,
    settings: state.settingReducer,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CheckOutContent);