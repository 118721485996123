import styled from "styled-components";
import BrandsCarousel from "../GlobalComponents/BrandList";
import { colors } from "../../res/values/values";
const Container = styled.div`
  display: block;
  padding: 1rem;
`;
const BrandsWrapper = styled.div`
  & h1 {
    margin: 0;
    margin-bottom: 0.7em;
    color: ${colors.primaryColor};
    font-size: 36px;
    font-weight: 600;
    font-style: normal;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
  }
  & p {
    margin: 0 0 1.5em;
    color: #231f20;
    font-size: 14px;
    line-height: 24px;
  }
`;
function HomeContent() {
  return (
    <Container>
      <BrandsWrapper>
        <h1>Brands</h1>
      </BrandsWrapper>
      <BrandsCarousel />
    </Container>
  );
}

export default HomeContent;
