import styled from "styled-components";

//#region Styles
const EachFeature = styled.div`
  display: flex;
  flex-direction: column;
  & p,
  a,
  tr,
  td,
  ul,
  li {
    color: #212b36;
    text-align: left;
    font-family: "Roboto";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4rem;
    text-decoration: none;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 0.9rem;
    }
  }
  & h2 {
    color: #212b36;
    text-align: left;
    font-family: "Roboto Slab";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    margin-bottom: 0.3rem;
    @media only screen and (max-width: 968px) {
      font-size: 1.1rem;
    }
    & p {
      color: #1aa6e1;
      text-align: left;
      font-family: "Roboto Slab";
      font-size: 2.18rem;
      font-style: normal;
      font-weight: 450;
      line-height: normal;
      @media only screen and (max-width: 968px) {
        font-weight: 450;
        font-size: 1.5rem;
      }
    }
  }
`;
const LabelDiv = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #1aa6e1;
  margin-bottom: 0.5rem;
  padding: 0.2rem 1.5rem;
  & h1 {
    color: #fff;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @media only screen and (max-width: 968px) {
      font-weight: 500;
      font-size: 1.3rem;
    }
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
//#endregion
function TermsAndConditionsContent() {
  return (
    <EachFeature>
      <LabelDiv>
        <h1>TERMS & CONDITIONS</h1>
      </LabelDiv>
      <h2>Definitions</h2>
      <p>In these terms and conditions:</p>
      <ul>
        <li>
          <strong>The Company</strong> means Supplies Limited (from herein
          referred to as) and all its divisions and subsidiary companies.
        </li>
        <li>
          <strong>The Customer</strong> means any person, firm, company, or
          other legal entity which places an order, or buys any Products from
          and includes the employees, agents, or sub-contractors of any such
          person, firm, company, or other legal entity.
        </li>
        <li>
          <strong>Food</strong> means any food or beverage.
        </li>
        <li>
          <strong>Equipment</strong> means any machine or equipment.
        </li>
        <li>
          <strong>Non-Food</strong> means any goods, which are not food or
          equipment.
        </li>
        <li>
          <strong>Contract</strong> means a contract between and the Customer
          for the sale and purchase of the Products.
        </li>
        <li>
          <strong>Products</strong> means any Food and/or Equipment and/or
          Non-Food agreed in the contract to be supplied by the Customer.
        </li>
        <li>
          <strong>Statutory Interest</strong> means statutory interest as
          defined in the Late Payment of Commercial Debts (Interest) Act 1998.
        </li>
      </ul>
      <br />
      <h2>Applicability of Terms and Conditions</h2>
      <p>
        These Terms and Conditions shall be incorporated into the Contract and
        shall apply in place of and prevail over any terms and conditions
        contained or referred to in any communication from the Customer or
        implied by the custom or practice. Other purported terms and conditions
        are expressly rejected by Best C. Veg Limited. The Company reserves the
        right to amend or update the current Terms and Conditions and notify the
        Customer of these changes.
      </p>
      <br />
      <h2>Orders and Contracts</h2>
      <p>
        By placing an order with Best C. Veg Limited via the Company’s website{" "}
        <a href="http://bestcateringuk.com/">http://bestcateringuk.com/</a>, the
        Customer is offering to contract with the Company based on these Terms
        and Conditions. The Contract shall be formed when the Company
        acknowledges acceptance of the Customer’s order or if delivery service
        offered by the Company and the Company or its appointed service company
        delivers the Products to the Customer, whichever occurs earlier. The
        Contract is subject to the availability of stock. Products are subject
        to availability and market conditions.
      </p>
      <br />
      <p>
        The Company may limit the quantities of Products supplied to the
        Customer. In the event of non-availability of any Products you order,
        the Company may offer a reasonable substitute. This may affect the
        price; the Customer pays the revised item price. The Customer reserves
        the right to reject any substitute Products and the Company will refund
        the amount charged for the substitute Products. Abusive behaviour from
        Customer to Company’s staff will not be tolerated; the Company reserves
        to right to refuse to sell to such Customers. Calls may be monitored for
        training and quality purposes.
      </p>
      <br />
      <p>
        All complete orders via the website will automatically send the Customer
        a confirmation e-mail. If this does not reach you, please contact{" "}
        <a href="mailto:sales@bestcateringuk.com">sales@bestcateringuk.com</a>.
        The Company reserves the right to change or terminate its offers on the
        website without any prior notice.
      </p>
      <br />
      <h2>Minimum Order</h2>
      <p>
        The minimum order value for delivery must be £300. Orders below this
        amount will not be delivered. The Company reserves the right not to
        deliver orders below the latest minimum order value.
      </p>
      <br />
      <h2>Deliveries</h2>
      <p>
        Deliveries of Products to the Customer shall be by the Company’s
        delivery schedule or as agreed with the Customers.
      </p>
      <br />
      <p>
        The Company does not guarantee delivery by the Customers’ requested
        timing schedule.
      </p>
      <br />
      <p>
        The Company shall not be liable for any loss or damage whatsoever
        arising because of failure to deliver Products by a particular date or
        arising because of any causes beyond the Company’s control.
      </p>
      <br />
      <p>
        The delivery of the Products shall be made by the Company to such
        reasonably accessible premises as the Customer shall require. The
        Customer shall be present at the premises to take and sign off the
        delivery of the Products. Deliveries will be made to the address
        specified by the Customer when the account was opened or updated. The
        Company reserves the right to restrict deliveries in certain areas, and
        this includes the right to eliminate certain areas from the Company’s
        delivery schedule.
      </p>
      <br />
      <p>
        If the Customer fails to take delivery or fails to give the Company
        adequate delivery instructions at the time stated for delivery then
        without prejudice to any other right or remedy available to the Company,
        the Company may do one or more of the following:
      </p>
      <br />
      <p>
        Charge the Customer the cost of carriage of the refused delivery both to
        and from the premises specified by the Customer in addition to the
        Company’s administration and re-stocking charges, which are available
        upon application
      </p>
      <br />
      <p>Charge full cost price for perishable Products</p>
      <br />
      <p>Charge full cost price for perishable Products.</p>
      <br />
      <p>Charge the Customer a re-scheduling delivery charge.</p>
      <br />
      <p>
        Shortages: shortages should be recorded at the time of delivery in the
        presence of the Company’s driver; the Customer will sign off the
        delivery note highlighting the shortages and countersigned by the
        Company’s driver.
      </p>
      <br />
      <p>
        The Company will not tolerate any abusive behaviours by the Customer
        towards its drivers and helpers, the Company reserves the right to
        refuse to deliver to such a customer.
      </p>
      <br />
      <h2>Risk & Ownership</h2>
      <p>
        All risks in the Products pass to the Customer on collection or
        delivery. Ownership of the Products collected or delivered to the
        Customer shall remain with the Company until the Products are paid for
        in full.
      </p>
      <br />
      <h2>Warranties</h2>
      <p>
        The Company warrants that all Products shall comply with all UK and EU
        regulations and legislations.
      </p>
      <br />
      <h2>Price</h2>
      <p>
        Contract Price is the order price, any special offers are subject to
        availability, and the prices quoted or shown on the Company literature
        are exclusive of VAT @ the standard rate where applicable. While every
        attempt has been made to make these prices accurate, mistakes may have
        been made. The Company reserves the right to refuse purchases made,
        based upon these possible errors.
      </p>
      <br />
      <h2>Payments</h2>
      <p>
        The Customer must settle all orders following agreed credit terms, in
        the event of the Customer account being in arrears, the Company reserves
        the right to withhold further supplies. The Company reserves the right
        to charge Statutory Interest on overdue balances for the period from the
        date, on which the payment was due.
      </p>
            <br />
      <ul>
        <li>
          <strong>New Customer Payments:</strong>
          <p>
            Unless otherwise agreed, all payments from new customers must be
            made by debit card, credit card, or bank transfer. Accepted debit
            and credit cards are those listed in our promotional materials or on
            our website. Authority for payment will be requested from your card
            issuer at the time of payment, and your card will be charged
            accordingly.
          </p>
          <br />
        </li>
        <li>
          <strong>Credit Accounts:</strong>
          <p>
            Customers may apply for a credit account after a minimum of three
            months of trading with the company, provided it is deemed
            appropriate. Until a favorable decision is made on the customer's
            credit account application, the customer must make payments
            according to the agreed payment terms.
          </p>
          <br />
        </li>
        <li>
          <strong>Credit Account Terms:</strong>
          <p>
            All invoices for one calendar month are due for payment on the
            agreed day of the following month. Customers must settle all
            accounts under agreed terms. In the event of the customer account
            being in arrears, the account will be placed on hold, and the
            company reserves the right to withhold further deliveries of goods.
          </p>
          <br />
        </li>
        <li>
          <strong>Non-Payment and Dishonored Payments:</strong>
          <p>
            In the event of non-payment, the company reserves the right to
            charge statutory interest on overdue balances from the date the
            payment was due. If any payment due to the company is dishonored, a
            charge of ₤30 (or such other sum as the company may from time to
            time advise the customer) will be made to the customer's account to
            cover bank and administrative costs. In the event of a dishonored
            payment, the customer must make all future payments using the agreed
            payment method, and the account will be placed on hold until all
            payments are received in full.
          </p>
          <br />
        </li>
        <li>
          <strong>Cash Payments:</strong>
          <p>
            The maximum amount of cash that will be accepted is £8,500. The
            maximum cash payment by coins allowed from any one customer is £60
            in £1 or £2 coins only.
          </p>
          <br />
        </li>
        <li>
          <strong>General:</strong>
          <p>
            The company reserves the right to start legal proceedings to recover
            any outstanding amount without prior notice and the right to
            exchange information with credit agencies. The company reserves the
            right to charge a fee if certain business cards, also known as
            corporate or commercial cards, are used for payment.
          </p>
          <br />
          <p>
            For a Credit Account Customer, any non or late payment will be
            considered a breach of Contract, will render Credit Terms null and
            void, the Customer’s account will be put on hold, and the Company
            has the right to start legal proceedings to recover any outstanding
            amount without any prior notice and the right to exchange
            information with Credit Agencies.
          </p>
          <br />
          <p>
            Maximum Cash Coin payment allowed at any one time by a customer is
            £60 (Sixty pounds Sterling) made up of £1 (One Pound Sterling) and
            £2 (Two Pound Sterling) coins only.
          </p>
          
        </li>
      </ul>
      <br />
      <h2>Returns</h2>
      <p>
        The Company will only credit the Customer if the wrong product was
        delivered, and The Customer advised the relevant Best C. Veg Limited
        branch on the same day with the same delivery driver. Customers are
        advised to check goods before leaving the Company premises as collected
        goods are Non-Refundable and returns are not allowed. Your statutory
        rights are not affected.
      </p>
      <br />
      <h2>Product Information</h2>
      <p>
        All the allergen information listed on our website has been carefully
        prepared. We are reliant on our suppliers to provide us with correct
        allergen information. It is important to understand that the product
        ingredients are liable to change at any time. Whist, we are committed to
        ensuring that the information we provide on our website is always
        precise and up to date; you should never solely depend on this
        information.
      </p>
      <br />
      <p>
        You must always read the label on the packaging of the product before
        consumption. This will detail if there is any cross-contamination or
        allergens. If you have any concerns or require further information, then
        you must contact the manufacturer directly.
      </p>
      <br />
      <p>
        Best C. Veg Limited is not responsible for any oversights made on behalf
        of the suppliers. Nor are we accountable for any incorrect information
        including product imagery listed on our website.
      </p>
    </EachFeature>
  );
}

export default TermsAndConditionsContent;
