import styled from "styled-components";

//#region Styles
const EachFeature = styled.div`
  display: flex;
  flex-direction: column;
  & p,
  a,
  tr,
  td,
  ul,
  li {
    color: #212b36;
    text-align: left;
    font-family: "Roboto";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4rem;
    text-decoration: none;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 0.9rem;
    }
  }
  & h2 {
    color: #212b36;
    text-align: left;
    font-family: "Roboto Slab";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    margin-bottom: 0.3rem;
    @media only screen and (max-width: 968px) {
      font-size: 1.1rem;
    }
    & p {
      color: #1aa6e1;

      text-align: left;
      font-family: "Roboto Slab";
      font-size: 2.18rem;
      font-style: normal;
      font-weight: 450;
      line-height: normal;
      @media only screen and (max-width: 968px) {
        font-weight: 450;
        font-size: 1.5rem;
      }
    }
  }
`;
const LabelDiv = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #1aa6e1;
  margin-bottom: 0.5rem;
  padding: 0.2rem 1.5rem;
  & h1 {
    color: #fff;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @media only screen and (max-width: 968px) {
      font-weight: 500;
      font-size: 1.3rem;
    }
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
//#endregion
function FAQContent() {
  return (
    <EachFeature>
      <LabelDiv>
        <h1>FAQ</h1>
      </LabelDiv>
      <h2>Delivery</h2>
      <ul>
        <li>How do I book a delivery slot?</li>
        <li>What days do you deliver?</li>
        <li>What's the minimum spend for delivery?</li>
        <li>How much does delivery cost?</li>
        <li>How can I change my delivery date?</li>
        <li>How is my shopping delivered?</li>
        <li>Who can sign for my delivery?</li>
        <li>What if I'm not at the address for the delivery?</li>
        <li>What if I have missing or damaged items?</li>
      </ul>
      <br />
      <h2>How to Shop</h2>
      <ul>
        <li>How do I register as a new customer?</li>
        <li>Can I place my order by phone?</li>
        <li>How do I search for a product?</li>
        <li>How do I change items in my basket?</li>
        <li>How do I check out?</li>
        <li>Can I save my order and finish it later?</li>
        <li>What if an item is out of stock?</li>
        <li>What if I ordered an unavailable item?</li>
      </ul>
      <br />
      <h2>My Account</h2>
      <ul>
        <li>How do I update my details?</li>
        <li>Forgot your password?</li>
        <li>How do I change my password?</li>
        <li>How do I update my billing/delivery address?</li>
        <li>How do I add a secondary delivery address?</li>
      </ul>
      <br />
      <h2>My Orders</h2>
      <ul>
        <li>How do I order?</li>
        <li>How do I cancel an order?</li>
        <li>How do I view my order history?</li>
        <li>How will I know my order is confirmed?</li>
        <li>Can I order from a different address?</li>
        <li>When will my order arrive?</li>
        <li>Can I return a product?</li>
      </ul>
      <br />
      <h2>Prices & Payments</h2>
      <ul>
        <li>Which credit/debit cards do you accept?</li>
        <li>How will I receive a receipt?</li>
        <li>How do I update my billing address?</li>
        <li>How do I add or change a payment method?</li>
        <li>When is payment taken?</li>
        <li>How long does a refund take?</li>
        <li>How often do you update prices?</li>
      </ul>
      <br />
      <h2>Products</h2>
      <ul>
        <li>How many products do you offer?</li>
        <li>How can I find in-stock products?</li>
        <li>What if a product is out of stock?</li>
        <li>Why do you stop selling some products?</li>
      </ul>
      <br />
      <h2>Refunds & Returns</h2>
      <ul>
        <li>What is your refund policy?</li>
        <li>What products cannot be returned to the store?</li>
        <li>Can I return a faulty or damaged product?</li>
        <li>How can I get a refund for missing items?</li>
      </ul>
      <br />
      <h2>Technical</h2>
      <ul>
        <li>I can't register. What should I do?</li>
        <li>I can't connect to the site. What now?</li>
        <li>I have issues adding or removing items from my basket.</li>
        <li>I can't sign into my account.</li>
        <li>When I try to sign in, I keep getting redirected.</li>
        <li>I can't access the checkout.</li>
        <li>I get errors when confirming my order at checkout.</li>
      </ul>
    </EachFeature>
  );
}

export default FAQContent;
