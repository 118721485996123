import styled from "styled-components";
import CheckOutContent from "../components/CheckOutPageComponents/CheckOutContent";
import { useEffect } from "react";
const Container = styled.div`
  display:flex ;
  flex:1 ;
  flex-direction:column ;
  justify-content:space-between ;
  max-width: 1280px;
`;
function Checkout() {
  useEffect(()=>{
    window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  },[])
  return (
    <Container>
      <CheckOutContent/>
    </Container>
  );
}

export default Checkout;
