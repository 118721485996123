import React, { useEffect } from "react";
import styled from "styled-components";
import { changeAndAddMetaTags } from "../../utilities/helpers";
import { useParams } from "react-router-dom";
import RestaturantSectorComponent from "./RestaturantSectorComponent";
import ShopBySector from "../HomeComponents/ShopBySector";
import BrandsCarousel from "../HomeComponents/BrandsCarousel";
import RangeComponent from "./RangeComponent";
import MobileMarketSectorComponent from "./MobileMarketSectorComponent";
import HotelsSectorComponent from "./HotelsSectorComponent";
import PubAndBarsSectorComponent from "./PubAndBarsSectorComponent";
import TakeawaysSectorComponent from "./TakeawaysSectorComponent";
import CafeSectorComponent from "./CafeSectorComponent";
import IndependentMarketSectorComponent from "./IndependentMarketSectorComponent";
import HospitalSectorComponent from "./HospitalSectorComponent";
const Container = styled.div`
  display:flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  width: 100%;
  padding-inline:.2rem ;
`;
const CarouselContainer = styled.div`
  background-color: #F4F6F8;
  width: 100%;
  display:flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
`;
const MainPage = () => {
    
    let params = useParams();
    useEffect(() => {
        window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
        });
    }, []);
    return (
        <Container>
            {changeAndAddMetaTags('Best Catering Food Service Online London | London','Best Catering Food Service Online London | London',document.URL)}
            {(params.sector==="restaurant") && <RestaturantSectorComponent/>}
            {(params.sector==="mobilemarket") && <MobileMarketSectorComponent/>}
            {(params.sector==="hotels") && <HotelsSectorComponent/>}
            {(params.sector==="pubandbars") && <PubAndBarsSectorComponent/>}
            {(params.sector==="takeaway") && <TakeawaysSectorComponent/>}
            {(params.sector==="cafe") && <CafeSectorComponent/>}
            {(params.sector==="independent") && <IndependentMarketSectorComponent/>}
            {(params.sector==="hospital") && <HospitalSectorComponent/>}
            <CarouselContainer>
                <RangeComponent/>
            </CarouselContainer>
            <BrandsCarousel />
        </Container>
    );
};

export default MainPage;
