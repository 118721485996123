import styled from "styled-components";

//#region Styles
const EachFeature = styled.div`
  display: flex;
  flex-direction: column;
  & span {
    color: #212b36;
    text-align: left;
    font-family: "Roboto";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4rem;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 0.9rem;
    }
    & span {
      color: #1aa6e1;
    }
  }
  & h3 {
    color: #5f5f5f;
    text-align: left;
    font-family: "Roboto";
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 0.9rem;
    }
  }
  & h1 {
    color: #212b36;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    @media only screen and (max-width: 968px) {
      font-weight: 500;
      font-size: 1.5rem;
    }
  }
  & h2 {
    color: #1aa6e1;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    margin-left: 0.5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 500;
      font-size: 1.5rem;
    }
  }
  & h4 {
    color: #212b36;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    @media only screen and (max-width: 968px) {
      font-weight: 450;
      font-size: 1.5rem;
    }
    & span {
      color: #1aa6e1;

      text-align: left;
      font-family: "Roboto Slab";
      font-size: 2.18rem;
      font-style: normal;
      font-weight: 450;
      line-height: normal;
      @media only screen and (max-width: 968px) {
        font-weight: 450;
        font-size: 1.5rem;
      }
    }
  }
  & h5 {
    color: #1aa6e1;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    margin-left: 0.5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 450;
      font-size: 1.5rem;
    }
  }
  &:hover {
    opacity: 1;
  }
`;
const LabelDiv = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const EachCategoryImage = styled.img`
  width: 100%;
  object-fit: contain;
  margin-bottom: 1.2rem;
`;
//#endregion
function AboutContent() {
  return (
    <EachFeature>
      <EachCategoryImage
        alt="best catering food service about us"
        loading="lazy"
        title="best catering food service about us"
        src={`./images/Best_Catering_About_Us.jpg`}
      ></EachCategoryImage>
      <LabelDiv>
        <h4>
          Discover the Fastest Growing London Food Wholesaler: Best Catering Ltd
        </h4>
      </LabelDiv>
      <span>
        Best Catering Ltd is proud to be the fastest-growing London food
        wholesaler, serving the entire UK with unparalleled dedication and
        efficiency. Based in North London, we provide next-day delivery services
        across the wholesale foodservice market, ensuring that all sectors
        receive the high-quality products they need, when they need them.
      </span>
      <br />
      <span>
        <strong>Your One-Stop Shop for All Catering Needs</strong>
      </span>
      <span>
        As a leading food and drink supplier, we are a one-stop shop for all
        your food and non-food delivery requirements. Our extensive product
        range includes best-selling branded products from reputable
        manufacturers, offering great quality across frozen and fresh foods,
        drinks, packaging, cleaning products, and workwear.
      </span>
      <br />
      <span>
        <strong>Our Growth Journey</strong>
      </span>
      <span>
        Best Catering Ltd began as a family business in 2009, operating out of a
        4,000 square foot space. Over the years, we've expanded significantly,
        reaching our current size of 29,800 square feet. In 2018, we launched a
        new Cash and Carry business, Extra Cash & Carry, located at Unit A,
        Brantwood Road, N17 0YD. This facility operates 24/7 and offers same-day
        collection for customers who prefer to pick up their orders.
      </span>
      <br />
      <span>
        <strong>Serving a Diverse Range of Customers</strong>
      </span>
      <span>
        We cater to a wide variety of food establishments, including café shops,
        fish bars, kebab shops, fried chicken shops, Chinese takeaways,
        restaurants, and sandwich bars. Our customer base is incredibly diverse,
        reflecting the broad spectrum of food services we support across London
        and the UK.
      </span>
      <br />
      <span>
        <strong>Innovative Ordering Solutions</strong>
      </span>
      <span>
        To enhance our service, Best Catering Ltd has heavily invested in our IT
        infrastructure. Customers can now easily place and pay for their orders
        online or via our app for next-day delivery. This streamlined process
        ensures faster and more efficient service, providing a significant
        advantage for our clients.
      </span>
      <br />
      <span>
        <strong>Our Commitment to Quality and Service</strong>
      </span>
      <span>
        We are passionate about what we do, and we firmly believe that our
        success is measured by the quality of our food, service, and customer
        experience. By working closely with our vendors and customers, we
        support our vision, mission, and values, striving to deliver excellence
        in every aspect of our business. If you would like to take advantage of
        the exceptional services offered by Best Catering Ltd, we would love to
        hear from you. Join us and experience the best in London catering
        services from a trusted food and drink supplier.
      </span>
    </EachFeature>
  );
}

export default AboutContent;
