import React, { useEffect } from "react";
import styled from "styled-components";
import AboutContent from "../components/PagesComponents/AboutContent";
import useWindowWidthAndHeight from "../utilities/hooks/useWindowWidthAndHeight";
import ImageSlider from "../components/GlobalComponents/ImageSlider";
import { changeAndAddMetaTags } from "../utilities/helpers";
import ImageComponent from "../components/PagesComponents/ImageComponent";
const Container = styled.div`
  width: 100%;
  max-width: 1280px;
  padding: .2rem;
`;

const About = () => {
  const { width } = useWindowWidthAndHeight();
  useEffect(()=>{
    window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  },[])
  useEffect(() => {
    changeAndAddMetaTags('About Us | Best Catering Food Service Online London','About Us | Best Catering Food Service Online London',document.URL)
  }, [])
  
  return (
    <Container>
      <AboutContent/>
    </Container>
  );
};

export default About;
