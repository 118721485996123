import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
} from "@material-ui/icons";
import styled from "styled-components";
import { colors } from "../../res/values/values";
import { useNavigate } from "react-router-dom";
import useWindowWidthAndHeight from "../../utilities/hooks/useWindowWidthAndHeight";
import ProductCardView from "./ProductCardView";
//#region Styles
const BodyDiv = styled.div`
  display: grid;
  //flex: 1;
  //flex-direction:column;
  position: relative;
  overflow: hidden;
  max-width: 1280px;
  width: 100%;
  //margin: 5px 10px;
  //width:${(props) => props.width - 100}px;
`;
const Glider = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;

  //box-shadow: 0px 3px 15px -1px rgb(0 0 0 / 10%);
  padding: 10px 10px;
  background-color: white;
`;
const Glide = styled.div`
  height: auto;
  transition: 0.5s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  @media only screen and (min-width: 380px) {
    min-width: 51%;
  }
  @media only screen and (min-width: 768px) {
    min-width: 33%;
  }
  @media only screen and (min-width: 1068px) {
    min-width: 25%;
  }
`;
const RightButton = styled.div`
  z-index: 2;
  position: absolute;
  color: ${(props) => (props.color === "black" ? "#000" : "#000")};
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1.5px;
  top: ${(props) => props.heightPosition}%;
  right: ${(props) => props.widthPosition}%;
  background-color: #fff;
  border-radius: 50%;
  //box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.4);
  height: 35px;
  cursor: pointer;
`;
const LeftButton = styled.div`
  z-index: 2;
  position: absolute;
  color: ${(props) => (props.color === "black" ? "#000" : "#000")};
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1.5px;
  top: ${(props) => props.heightPosition}%;
  left: ${(props) => props.widthPosition}%;
  background-color: #fff;
  border-radius: 50%;
  //box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.4);
  height: 35px;
  cursor: pointer;
`;

const TitleContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  
`;
const TitleLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Title = styled.span`
  color: #212b36;
  font-family: "Roboto";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media only screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;
const SubTitle = styled.span`
  color: #d22424;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media only screen and (max-width: 768px) {
    font-size: 0.85rem;
  }
`;
const TitleViewAll = styled.span`
  color: #1aa6e1;
  font-family: "Roboto";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: right;
  width: 100%;
  background-color:white;
  @media only screen and (max-width: 768px) {
    font-size: 0.85rem;
    width: 98%;
  }
  &:hover {
    //background-color: ${colors.primaryColor};
    color: ${colors.darkcolor};
    cursor: pointer;
  }
`;
const Divider = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #cacaca;
`;
const HeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 163px;
  & h1 {
    color: #212b36;

    text-align: center;
    font-family: "Roboto Slab";
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 1.6rem;
    }
  }
  & p {
    color: #1aa6e1;

    text-align: center;
    font-family: "Roboto Slab";
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 0.5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 1.6rem;
    }
  }
  & span {
    color: #9a9a9a;
    text-align: center;
    font-family: "Roboto";
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 1rem;
    }
  }
`;
const LabelDiv = styled.div`
  display: flex;
  flex-direction: row;
`;
//#endregion
function Carousal(props) {
  const [weboffers, setWebOffers] = useState([]);
  const [value, setValue] = useState(0);
  const [timeId, setTimeId] = useState("");
  const [clickButton, setClickButton] = useState(false);
  const navigate = useNavigate();
  const { height, width } = useWindowWidthAndHeight();
  useEffect(() => {
    let isMounted = true;
    const getWebOffers = async () => {
      let url = "";
      if (props.type === "newitems")
        url = `${process.env.REACT_APP_API}/products/newitems?customerprice=${props.currentUser.customerpricegroup}&customerno=${props.currentUser.customerno}`;
      else
        url = `${process.env.REACT_APP_API}/products/weboffers?customerprice=${props.currentUser.customerpricegroup}&customerno=${props.currentUser.customerno}`;
      await fetch(url)
        .then((res) => res.json())
        .then((res) => {
          if (isMounted)
            setWebOffers(res.message.data);
        });
    };
    getWebOffers();
    return () => {
      isMounted = false; // Bileşen unmounted olduğunda bayrağı değiştir
    };
  }, [props.currentUser]);
  useEffect(() => {
    let isMounted = true;
    if (!clickButton) {
      if (timeId !== "") clearTimeout(timeId);
      let id = setTimeout(() => {
        const viewSize =
          width < 380 ? 1 : width < 768 ? 2 : width < 1068 ? 3 : 4;
        if (weboffers.length !== 0 && weboffers.length !== viewSize) {
          if(isMounted){
            value === -100 * (weboffers.length - viewSize)
              ? setValue(0)
              : setValue(value - 100);
          }
          
        }
      }, 4000);
      setTimeId(id);
    } else {
      if (timeId !== "") clearTimeout(timeId);
    }
    return () => {
      isMounted = false; // Bileşen unmounted olduğunda bayrağı değiştir
    };
  }, [value, weboffers]);
  const moveBehind = () => {
    const viewSize = width < 380 ? 1 : width < 768 ? 2 : width < 1068 ? 3 : 4;
    value === -100 * (weboffers.length - viewSize)
      ? setValue(0)
      : setValue(value - 100);
    setClickButton(true);
  };
  const moveAhead = () => {
    const viewSize = width < 380 ? 1 : width < 768 ? 2 : width < 1068 ? 3 : 4;
    value === 0
      ? setValue(-100 * (weboffers.length - viewSize))
      : setValue(value + 100);
    setClickButton(true);
  };
  if (weboffers.length > 0)
    return (
      <BodyDiv width={width}>
        <TitleContent>
          <TitleLeft />
          <TitleLeft>
            <HeaderDiv>
              <LabelDiv>
                <h1>{props.type === "newitems" ? "NEW" : "LATEST"} </h1>
                <p>{props.type === "newitems" ? "PRODUCTS" : "OFFERS"} </p>
              </LabelDiv>
              <span>
                {props.type === "newitems"
                  ? "Don't Miss Our New Products. Stay Tuned"
                  : "Don't Miss Out On Our Discounts. Stay Tuned With Us"}{" "}
              </span>
            </HeaderDiv>
          </TitleLeft>
        </TitleContent>
        <TitleViewAll
          onClick={() =>
            props.type === "magnificentseven"
              ? navigate("/search?filter=magnificentseven")
              : navigate("/search?filter=weboffers")
          }
        >
          {" View All"}
        </TitleViewAll>
        <LeftButton
          onClick={moveAhead}
          heightPosition={45}
          widthPosition={-0.1}
        >
          <KeyboardArrowLeftOutlined fontSize="large" />
        </LeftButton>
        <RightButton
          onClick={moveBehind}
          heightPosition={45}
          widthPosition={-0.1}
        >
          <KeyboardArrowRightOutlined fontSize="large" />
        </RightButton>

        <Glider width={width}>
          {weboffers.map((product, index) => {
            return (
              <Glide key={index} style={{ transform: `translateX(${value}%)` }}>
                <ProductCardView
                  key={product.itemno}
                  product={product}
                  viewType={"Grid"}
                />
              </Glide>
            );
          })}
        </Glider>
      </BodyDiv>
    );
  else return <BodyDiv />;
}
function mapStateToProps(state) {
  return {
    currentUser: state.currentUserReducer,
  };
}
export default connect(mapStateToProps, null)(Carousal);
