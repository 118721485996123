import styled from "styled-components";
import { colors } from "../res/values/values";
const Container = styled.div`

`;
const MessageContainer = styled.div`
  min-height: 40vw;
  display:flex ;
  flex-direction:column ;
  flex:1 ;
  align-items:center ;
  padding:2rem ;
  max-width: 1280px;
`;
const Label = styled.h1`
  font-size: 3rem;
  color: ${colors.primaryColor};
  font-weight: 700;
  text-align:center ;
`;
const Text = styled.span`
  font-size: 2rem;
  color: black;
  font-weight: 600;
  text-align:center ;
`;
function Maintenance() {

  return (
    <Container >
      <MessageContainer>
        <Label>Maintenance Mode</Label>
        <Text>
          We are currently performing some scheduled maintenance. We will be
          back as soon as possible. Please check back soon.
        </Text>
      </MessageContainer>
    </Container>
  );
}

export default Maintenance;
