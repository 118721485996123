//003 ++ Check Out Page Payment Method Kotrolünün Eklenmesi.

import * as React from "react";
import { useState } from "react";
import FormControl from "@mui/material/FormControl";
import { Checkbox } from "@mui/material";
import styled from "styled-components";
import { colors } from "../../res/values/values";

//#region STYLES

const Container = styled.div`
  min-height: 10rem;
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: lightgray;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
`;
const Button = styled.div`
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 1px;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  background-color: ${colors.primaryColor};
  border-color: #dddddd #dddddd #b3b3b3 #b7b7b7;
  color: #fff;
  border-radius: 2px;
  margin: 2px;
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.darkcolor};
  }
`;

const Title = styled.span`
  font-size: 1rem;
  color: ${colors.primaryColor};
  font-weight: 500;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
  flex: 3;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const MultiInput = styled.textarea`
  margin: 10px 0;
  padding: 10px;
  border: 1px;
  height: 2vw;
  border-color: lightgray;
  border-style: solid;
  text-align: start;
  &:focus {
    outline: none !important;
    border-color: ${colors.primaryColor};
  }
`;
//#endregion

export default function OrderCommentComponent(props) {
  const [comment, setComment] = useState(props.p_comment);
  const handleChange = (event) => {
    setComment(event.target.value);
  };
  return (
    <Container>
      <FormControl>
        <Title>
            Please enter comment to use on this order.
        </Title>
      </FormControl>
      <div>
          <Form>
            <MultiInput
              name="message"
              onChange={handleChange}
              type="text"
              placeholder="order comment (optional)"
              value={comment}
            />
          </Form>
        </div>
      <ButtonContainer>
        <Button
          onClick={() => {
            if(comment.length > 249) {
                alert("Order comment cannot be larger than 250 characters !");
            }else {
                props.changeOrderComment(comment);
                props.changeOpenStatus(true);
            }
          }}
        >
          Continue
        </Button>
      </ButtonContainer>
    </Container>
  );
}
