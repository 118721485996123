import styled from "styled-components";
// #region Style
const EachFeature = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  width: 100%;
  @media only screen and (max-width: 768px) {
    margin: 1rem 1rem;
  }
  & span {
    color: #212b36;
    text-align: left;
    font-family: "Roboto";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4rem;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 0.9rem;
    }
    & span {
      color: #1aa6e1;
    }
  }
  & h3 {
    color: #5f5f5f;
    text-align: left;
    font-family: "Roboto";
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 0.9rem;
    }
  }
  & h1 {
    color: #212b36;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    @media only screen and (max-width: 968px) {
      font-weight: 500;
      font-size: 1.5rem;
    }
  }
  & h2 {
    color: #1aa6e1;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    margin-left: 0.5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 500;
      font-size: 1.5rem;
    }
  }
  & h4 {
    color: #212b36;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    @media only screen and (max-width: 968px) {
      font-weight: 450;
      font-size: 1.5rem;
    }
    & span {
      color: #1aa6e1;

      text-align: left;
      font-family: "Roboto Slab";
      font-size: 2.18rem;
      font-style: normal;
      font-weight: 450;
      line-height: normal;
      @media only screen and (max-width: 968px) {
        font-weight: 450;
        font-size: 1.5rem;
      }
    }
  }
  & h5 {
    color: #1aa6e1;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    margin-left: 0.5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 450;
      font-size: 1.5rem;
    }
  }
  &:hover {
    opacity: 1;
  }
`;
const LabelDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const EachCategoryImage = styled.img`
  width: 100%;
  object-fit: contain;
  margin-bottom: 1.2rem;
`;
// #endregion
function CafeSectorComponent() {
  return (
    <EachFeature>
      <LabelDiv>
        <h4>BEST CATERING: EXCELLENCE IN THE WORLD OF SANDWICHES</h4>
      </LabelDiv>

      <h3>Your Trusted Partner for 15 Years</h3>
      <EachCategoryImage
        src={"/catalog/ShopBySector/cafe-and-sandwich.png"}
      ></EachCategoryImage>
      <span>
        A quintessential element of British cuisine, the sandwich is a
        cornerstone of the UK's food scene, delivering both convenience and
        versatility for those on the go. For the past 15 years, Best Catering
        has built a reputation as a trusted partner for cafés and sandwich bars
        throughout the UK, offering an extensive selection of food items,
        cleaning supplies, and packaging solutions.
      </span>
      <LabelDiv>
        <h4>
          <span>DISCOVER</span> OUR EXTENSIVE PRODUCT RANGE
        </h4>
      </LabelDiv>
      <span>
        Our extensive product range covers everything from signature sandwiches
        to brunch favorites, hot and cold drinks to healthy options. We bring
        everything under one roof, including practical fillings and
        ready-to-bake bakery items, so you can easily find everything you need.
      </span>
      <LabelDiv>
        <h4>
          WHAT <span>AWAITS YOU</span> AT BEST CATERING?
        </h4>
      </LabelDiv>
      <span>Breakfast Items</span>
      <span>
        We comprehensively support your sandwich bar or café menu with
        everything from breakfast items and the finest cheese brands to various
        olives and oils.
      </span>
      <span>Ready-to-Bake Bakery Items</span>
      <span>
        Our ready-to-bake bakery items, from croissants and tortillas to paninis
        and bagels, cater to every need.
      </span>
      <LabelDiv>
        <h4>
          <span>WHY CHOOSE</span> BEST CATERING?
        </h4>
      </LabelDiv>
      <span>Easy Online Ordering</span>
      <span>
        You can place your order online, by phone, or in person for a quick and
        easy shopping experience.
      </span>
      <br />
      <span>
        <span>One-Stop Shopping</span>
      </span>
      <span>
        Find everything you need under one roof. One order, one delivery, one
        trusted partner.
      </span>
      <span>
        <span>Food Safety</span>
      </span>
      <span>
        All products are purchased, maintained, stored, and transported
        according to the highest food safety standards.
      </span>
      <span>
        <span>Seasonal Variety</span>
      </span>
      <span>
        We collaborate with suppliers from the UK and around the world to
        provide fresh and high-quality products year-round.
      </span>
      <span>
        <span>Multi-Temperature Vehicles</span>
      </span>
      <span>
        Our state-of-the-art multi-temperature vehicles ensure all your needs
        are met in a single delivery.
      </span>
      <span>
        <span>Fast and Reliable Service</span>
      </span>
      <span>
        We offer fast and reliable service with delivery seven days a week or
        next-day delivery options.
      </span>
      <br />
      <span>
        At Best Catering, you can find both convenience and quality. Meet your
        needs with us and enhance the flavor of your sandwiches!
      </span>
      <br />
      <span>
        <strong>
          {" "}
          ***Delivery days can differ based on where you are located.
        </strong>
      </span>
    </EachFeature>
  );
}

export default CafeSectorComponent;
