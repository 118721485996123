import styled from "styled-components";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { colors } from "../../res/values/values";
import { ArrowBack } from "@material-ui/icons";
import { capitalizeFirstLetter } from "../../utilities/helpers";
//#region styles
const Accordion = styled.div`
  font-size: 1.1rem;
  letter-spacing: 1.5px;
  font-weight: 300;
  margin-bottom: 5px;
  margin-top: 1rem;
  padding-inline: 1rem;
  overflow-y: auto;
`;
const AccordionDiv = styled.div`
  overflow-y: auto;
  white-space: nowrap;

  @media only screen and (max-width: 600px) {
  }
`;
const MainItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  list-style: none;
  cursor: pointer;
  margin-top: .5rem;
  & h2 {
    color: #212b36;
    font-family: "Roboto";
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
const Element = styled.div`
  list-style: none;
  cursor: pointer;
  & a {
    color: #212b36;
    font-family: "Roboto";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.5rem;
  }
`;
const IconImage = styled.img`
  height: 1rem;
  width: 1rem;
  min-height: 1rem;
  object-fit: contain;
  margin-left: 0.3rem;
`;
//#endregion
function MobileMenuAccordionContent(props) {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (props.openIndex === props.index) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [props.openIndex]);

  const changeIsActiveStatus = () => {
    //props.setResetIsActive(!props.resetIsActive);
    //setIsActive(!isActive);
    if(props.subCategoryList.length > 0){
        isActive ? props.setOpenIndex(-1) : props.setOpenIndex(props.index);
    }else{
        navigate("/product-categories/" + props.currentCategory.seodescription);
        props.setOpenIndex(-1);
        props.close();
    }
    
  };
  const handleLinkClick = (event, cat) => {
    event.preventDefault();
    navigate("/product-categories/" + cat.seodescription);
    props.setOpenIndex(-1);
    props.close();
  };
  return (
    <Accordion>
      <MainItem onClick={() => changeIsActiveStatus()}>
        <h2>{capitalizeFirstLetter(props.currentCategory.description)}</h2>
        {props.subCategoryList.length > 0 && (
          <IconImage src={`./images/down.png`} />
        )}
      </MainItem>
      <AccordionDiv>
        {isActive && (
          <>
            {props.subCategoryList.map((value, index) => (
              <Element key={index}>
                <a
                  href={"/product-categories/" + value.seodescription}
                  onClick={(event) => handleLinkClick(event, value)}
                  title={value.seodescription}
                >
                  {capitalizeFirstLetter(value.description)}
                </a>
              </Element>
            ))}
            <Element>
              <a
                href={"/product-categories/" + props.currentCategory.seodescription}
                onClick={(event) => handleLinkClick(event, props.currentCategory)}
                title={props.currentCategory.seodescription}
              >
                View All
              </a>
            </Element>
          </>
        )}
      </AccordionDiv>
    </Accordion>
  );
}

export default MobileMenuAccordionContent;
//{(data.type==="ProductGroup" && isActive) && <Return onClick={()=>{returnPreviousCategory()}}> RETURN TO PREVIOUS CATEGORIES</Return>}
