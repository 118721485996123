import styled from "styled-components";
// #region Style
const EachFeature = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  width: 100%;
  @media only screen and (max-width: 768px) {
    margin: 1rem 1rem;
  }
  & span {
    color: #212B36;
    text-align: left;
    font-family: 'Roboto';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4rem;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: .9rem;
    
    }
  }
  & h3 {
    color: #5F5F5F;
    text-align: left;
    font-family: 'Roboto';
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top:.5rem ;
    margin-bottom:.5rem ;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: .9rem;
    
    }
  }
  & h1 {
    color: #212B36;

    text-align: left;
    font-family: 'Roboto Slab';
    font-size: 2.18rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    @media only screen and (max-width: 968px) {
      font-weight: 500;
      font-size: 1.5rem;
    
    }
  }
  & h2 {
    color: #1AA6E1;

    text-align: left;
    font-family: 'Roboto Slab';
    font-size: 2.18rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    margin-left: .5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 500;
      font-size: 1.5rem;
    
    }
  }
  & h4 {
    color: #212B36;

    text-align: left;
    font-family: 'Roboto Slab';
    font-size: 2.18rem;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    @media only screen and (max-width: 968px) {
      font-weight: 450;
      font-size: 1.5rem;
    
    }
    & span {
      color: #1aa6e1;

      text-align: left;
      font-family: "Roboto Slab";
      font-size: 2.18rem;
      font-style: normal;
      font-weight: 450;
      line-height: normal;
      @media only screen and (max-width: 968px) {
        font-weight: 450;
        font-size: 1.5rem;
      }
    }
  }
  & h5 {
    color: #1AA6E1;

    text-align: left;
    font-family: 'Roboto Slab';
    font-size: 2.18rem;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    margin-left: .5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 450;
      font-size: 1.5rem;
    
    }
  }
  &:hover {
    opacity: 1;
  }
`;
const LabelDiv = styled.div`
  display: flex;
  flex-direction: row; 
  margin-top: 1rem;
  @media only screen and (max-width: 768px) {
    flex-direction: column;  
  }
`;
const EachCategoryImage = styled.img`
  width: 100%;
  object-fit: contain;
  margin-bottom: 1.2rem;
 
`;
// #endregion
function RestaturantSectorComponent() {
  return (
    <EachFeature>
        <LabelDiv>
          <h4>CATERING EXCELLENCE FOR <span>RESTAURANTS & CASUAL DININGS</span></h4>
        </LabelDiv>
    
        <h3>Best Catering Food Delivery Service - Trust and Quality Built on 15 Years of Experience</h3>
        <EachCategoryImage
              src={"/catalog/ShopBySector/restaurant-dining.png"}
        ></EachCategoryImage>
        <span>
        For over 15 years, we have been leveraging our extensive experience to provide our customers with the best and most reliable service. With a distribution fleet of nearly 100 vehicles, we cater to the needs of food businesses and other establishments both in and around London.
        Our wide product range includes fresh produce, frozen meals, frozen foods, ready-to-eat meals, chilled foods, groceries, beverages, drinks, fresh fruits and vegetables, takeaway cups, and cleaning supplies
        </span>
        <span>Ordering is incredibly easy and quick through our Website and App. </span>
        <span>
        Choose us for quality service and a rich variety of products.
        </span>
        <LabelDiv>
          <h4>WE ARE <span>A TRUSTED COMPANY</span></h4>
        </LabelDiv>
        <span>Thousands of restaurants across the UK already trust us to deliver their fresh, chilled, frozen, and ambient products.</span>
        <LabelDiv>
          <h4>WHY RESTAURANTS <span>CHOOSE US</span></h4>
        </LabelDiv>
        <span>Here's why we stand out as your go-to restaurant supplier:</span>
        <span>✓ Year-Round Freshness - Partnering with UK and global suppliers, we ensure fresh produce is available throughout the year.</span>
        <span>✓ Convenient Ordering - Enjoy a seamless shopping experience by ordering online, over the phone, or in person.</span>
        <span>✓ High-Quality Selection - With Best Catering Order, access an extensive range of premium, fresh, made-to-order products.</span>
        <span>✓ Menu Variety - Premium, fresh, locally sourced products - Enjoy the finest ingredients sourced from local suppliers.</span>
        <span>✓ Convenient online ordering - Make your orders easily through our user-friendly online platform.</span>
        <span>✓ Friendly, helpful team - Our dedicated team is always ready to assist you.</span>
        <span>✓ Speedy, Dependable Service - Take advantage of our reliable collection service seven days a week or opt for next-day delivery*.</span>
        
        <LabelDiv>
          <h4>OUR COMMITMENT TO <span>QUALITY AT THE BEST CATERING</span></h4>
        </LabelDiv>
        <span>
            At Best Catering, we collaborate with carefully chosen suppliers to ensure consistent quality and Flavors that are unique to our brand. This dedication results in everyday value on high-quality products that deliver great taste, every time.
            Best Catering proudly adheres to all required accreditations, ensuring that every product is purchased, maintained, stored, and transported according to the highest standards of food safety.
        </span>
        <span>
           <strong> ***Delivery days can differ based on where you are located.</strong>
        </span>
    </EachFeature>
  );
}

export default RestaturantSectorComponent;
