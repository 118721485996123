import styled from "styled-components";
// #region Style
const EachFeature = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  width: 100%;
  @media only screen and (max-width: 768px) {
    margin: 1rem 1rem;
  }
  & span {
    color: #212b36;
    text-align: left;
    font-family: "Roboto";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4rem;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 0.9rem;
    }
    & span {
      color: #1aa6e1;
      font-weight: bold;
    }
  }
  & h3 {
    color: #5f5f5f;
    text-align: left;
    font-family: "Roboto";
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 0.9rem;
    }
  }
  & h1 {
    color: #212b36;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    @media only screen and (max-width: 968px) {
      font-weight: 500;
      font-size: 1.5rem;
    }
  }
  & h2 {
    color: #1aa6e1;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    margin-left: 0.5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 500;
      font-size: 1.5rem;
    }
  }
  & h4 {
    color: #212b36;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    @media only screen and (max-width: 968px) {
      font-weight: 450;
      font-size: 1.5rem;
    }
    & span {
      color: #1aa6e1;

      text-align: left;
      font-family: "Roboto Slab";
      font-size: 2.18rem;
      font-style: normal;
      font-weight: 450;
      line-height: normal;
      @media only screen and (max-width: 968px) {
        font-weight: 450;
        font-size: 1.5rem;
      }
    }
  }
  & h5 {
    color: #1aa6e1;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    margin-left: 0.5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 450;
      font-size: 1.5rem;
    }
  }
  &:hover {
    opacity: 1;
  }
`;
const LabelDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const EachCategoryImage = styled.img`
  width: 100%;
  object-fit: contain;
  margin-bottom: 1.2rem;
 
`;
// #endregion
function TakeawaysSectorComponent() {
  return (
    <EachFeature>
      <LabelDiv>
        <h4>
          TRUSTED WHOLESALE SUPPLIER FOR LONDON <span>TAKEAWAYS</span> : BEST
          CATERING
        </h4>
      </LabelDiv>

      <h3>
        Comprehensive Packaging and Food Solutions For Efficient And
        Cost-Effective Operations
      </h3>
      <EachCategoryImage
        src={"/catalog/ShopBySector/takeaways.png"}
      ></EachCategoryImage>
      <span>
        Best Catering has been a trusted supplier to UK takeaways and Quick
        Service Restaurants for over 15 years. I would like to talk about the
        products that takeaway businesses in London need the most. At Best
        Catering, we offer our brand, King Pack, which meets all the packaging
        needs of takeaway businesses economically and efficiently. You can add
        your sticker logos to our products, enhancing your brand visibility. Our
        King Pack pizza boxes are available in all sizes and are known for their
        durability.
      </span>
      <br />
      <span>
        We offer a wide range of products to facilitate the daily operations of
        takeaway businesses and reduce costs. Here are some of our products :
      </span>
      <br />
      <span>
        <span>Wraps and Bread Varieties: </span>Enrich your menus with various
        sizes and types of wraps, sandwich breads, and burger buns.
      </span>
      <span>
        <span>Burger Buns: </span>Our diverse range of burger buns, available in
        various sizes and types, will complement your fresh and tasty products.
      </span>
      <span>
        <span>Halal Meat Products: </span>We provide high-quality halal meat
        products, catering to the religious sensitivities of our customers.
      </span>
      <span>
        <span>Spices : </span>We supply various spices and sauces to add flavor
        to your dishes and equip your kitchens.
      </span>
      <span>
        <span>Frozen Products: </span>Our frozen products offer practical and
        economical solutions, reducing your workload while allowing you to serve
        quickly without compromising on freshness.
      </span>
      <span>
        <span>Fresh Fruits and Vegetables: </span>Add a touch of nature to your
        menus with fresh and high-quality fruits and vegetables available all
        year round. We offer a variety of products for use in salads, garnishes,
        and main dishes.
      </span>
      <span>
        <span>Dairy Products: </span>Our extensive range of dairy products,
        including cheese, yogurt, milk, and more, provides delicious and
        nutritious options.
      </span>
      <br />
      <span>
        At Best Catering, we aim to keep customer satisfaction at the highest
        level by offering high-quality products at affordable prices. With our
        products that meet all the needs of takeaway businesses in London, we
        aim to make your operations more efficient. By working with us, you can
        source your packaging and food product needs from a reliable supplier
        and provide the best service to your customers.
      </span>
      <br />
      <span>
        <strong>
          {" "}
          ***Delivery days can differ based on where you are located.
        </strong>
      </span>
    </EachFeature>
  );
}

export default TakeawaysSectorComponent;
