import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {colors} from "../../res/values/values"
import { useEffect, useState } from "react";
import { splitArray } from "../../utilities/helpers";
//#region Styles
const AccountMenuDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;

`;
const AccountMenuColumnDiv = styled.div`
  align-items: flex-start;
  display: flex;
  margin:1rem;
  flex-direction: column;
  width: 50%;

`;
const AccountButton = styled.a`
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px;
  align-items: center;

  color: ${colors.primaryColor};
  //padding: .5rem;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: ${colors.secondryColor};
    text-decoration: underline;
  }
`;

//#endregion
const SubCategoryMenuContent = (props) => { 
  const [list, setlist] = useState([])
    useEffect(() => {
      const splitArrayList = splitArray( props.subCategoryList,5)
      setlist(splitArrayList)
    }, [])
    
  const navigate = useNavigate();
  const handleLinkClick = (event, cat) => {
    event.preventDefault();
    navigate("/product-categories/" + cat.seodescription);
  };
  return (
    <AccountMenuDiv>
      {list.map((listItem,index) =>{
        return(
        <AccountMenuColumnDiv key={index}>
            {
                  listItem.map((cat,index)=>{
                    return (
                        <AccountButton 
                          key={index}
                          href={"/product-categories/" + cat.seodescription}
                          onClick={(event) => handleLinkClick(event, cat)}
                          title={cat.seodescription}
                        >{cat.description}</AccountButton>
                    )
                })
            }  
          </AccountMenuColumnDiv>
        )
      })}
    </AccountMenuDiv>
  );
};
export default SubCategoryMenuContent;
