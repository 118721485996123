import styled from "styled-components";

//#region Styles
const EachFeature = styled.div`
  display: flex;
  flex-direction: column;
  & p,
  a,
  tr,
  td,
  ul,
  li {
    color: #212b36;
    text-align: left;
    font-family: "Roboto";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4rem;
    text-decoration: none;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 0.9rem;
    }
    & p {
      color: #1aa6e1;
    }
  }
  & h2 {
    color: #212b36;
    text-align: left;
    font-family: "Roboto Slab";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    margin-bottom: 0.3rem;
    @media only screen and (max-width: 968px) {
      font-size: 1.1rem;
    }
    & p {
      color: #1aa6e1;

      text-align: left;
      font-family: "Roboto Slab";
      font-size: 2.18rem;
      font-style: normal;
      font-weight: 450;
      line-height: normal;
      @media only screen and (max-width: 968px) {
        font-weight: 450;
        font-size: 1.5rem;
      }
    }
  }  
`;
const LabelDiv = styled.div`
  display: flex;
  flex-direction: row;
  background-color:#1AA6E1;
  margin-bottom:.5rem;
  padding:.2rem 1.5rem;
  & h1 {
    color: #fff;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @media only screen and (max-width: 968px) {
      font-weight: 500;
      font-size: 1.3rem;
    }
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
//#endregion
function PrivacyPolicyContent() {
  return (
    <EachFeature>
    <LabelDiv>
        <h1>PRIVACY POLICY</h1>
    </LabelDiv>
      <p>
        Best Catering Food Service is a trading name of Best C. Veg Limited and
        we are committed to protecting your right to privacy as an individual.
        This affects you, not only if you are running your own business as a
        sole trader, or as a partner, but also if you are an employee.
      </p>
      <br />
      <p>
        This privacy policy sets out how Best C. Veg Limited Catering uses and
        protects any information that you give Best C. Veg Limited when you use
        this website. Best C. Veg Limited is committed to ensuring that your
        privacy is protected. Should we ask you to provide certain information
        by which you can be identified when using this website, then you can be
        assured that it will only be used by this privacy statement. Best C. Veg
        Limited may change this policy from time to time by updating this page.
        You should check this page from time to time to ensure that you are
        happy with any changes.
      </p>
      <br />
      <h2>What we collect</h2>
      <p>
        When you place an order, we need to know your name, billing address,
        trading address, email address, and telephone number so that we can
        process your order and get back to you if we have any queries.
      </p>
      <ul>
        <li>Name</li>
        <li>Contact information including email address</li>
        <li>
          Demographic information such as postcode, preferences, and interests
        </li>
        <li>Other information relevant to customer surveys and/or offers</li>
      </ul>
      <br />
      <h2>What we do with the information we gather</h2>
      <p>
        We require this information to understand your needs and provide you
        with a better service, and for the following reasons:
      </p>
      <ul>
        <li>Internal record keeping.</li>
        <li>
          We may use the information to improve our products and services.
        </li>
        <li>
          We may periodically send promotional emails about new products,
          special offers, or other information that we think you may find
          interesting using the email address that you have provided.
        </li>
        <li>
          From time to time, we may also use your information to contact you for
          market research purposes. We may contact you by email, phone, fax, or
          mail. We may use the information to customize the website according to
          your interests.
        </li>
      </ul>
      <br />
      <h2>Security</h2>
      <p>
        We are committed to ensuring that your information is secure. To prevent
        unauthorized access or disclosure, we have put in place suitable
        physical, electronic, and managerial procedures to safeguard and secure
        the information we collect online. We may have to disclose your
        information to third-party contractors to process your order, however,
        it will not be disclosed to any organization outside Best C. Veg Limited
        for any purpose other than for the execution of your order.
      </p>
      <br />
      <h2>How we use cookies</h2>
      <p>
        A cookie is a small file that asks permission to be placed on your
        computer's hard drive. Once you agree, the file is added, and the cookie
        helps analyse web traffic or lets you know when you visit a particular
        site. Cookies allow web applications to respond to you as an individual.
        The web application can tailor its operations to your needs, likes, and
        dislikes by gathering and remembering information about your
        preferences.
      </p>
      <br />
      <p>
        We use traffic log cookies to identify which pages are being used. This
        helps us analyse data about web page traffic and improve our website to
        tailor it to customer needs. We only use this information for
        statistical analysis purposes and then the data is removed from the
        system.
      </p>
      <br />
      <p>
        Overall, cookies help us provide you with a better website, by enabling
        us to monitor which pages you find useful and which you do not. A cookie
        in no way gives us access to your computer or any information about you,
        other than the data you choose to share with us. You can choose to
        accept or decline cookies. Most web browsers automatically accept
        cookies, but you can usually modify your browser settings to decline
        cookies if you prefer. This may prevent you from taking full advantage
        of the website.
      </p>
      <br />
      <h2>Links to other websites</h2>
      <p>
        Our website may contain links to other websites of interest. However,
        once you have used these links to leave our site, you should note that
        we do not have any control over that other website. Therefore, we cannot
        be responsible for the protection and privacy of any information that
        you provide whilst visiting such sites, and such sites are not governed
        by this privacy statement. You should exercise caution and look at the
        privacy statement applicable to the website in question.
      </p>
      <br />
      <h2>Controlling your personal information</h2>
      <p>
        You may choose to restrict the collection or use of your personal
        information in the following ways:
      </p>
      <br />
      <ul>
        <li>
          Whenever you are asked to fill in a form on the website, look for the
          box that you can click to indicate that you do not want the
          information to be used by anybody for direct marketing purposes.
        </li>
        <li>
          If you have previously agreed to us using your personal information
          for direct marketing purposes, you may change your mind at any time by
          writing to or emailing us at{" "}
          <a href="mailto:sales@bestcateringuk.com">sales@bestcateringuk.com</a>
          .
        </li>
      </ul>
      <br />
      <p>
        We will not sell, distribute, or lease your personal information to
        third parties unless we have your permission or are required by law to
        do so. We may use your personal information to send you promotional
        information about third parties which we think you may find interesting
        if you tell us that you wish this to happen.
      </p>
      <br />
      <p>
        You may request details of personal information which we hold about you
        under the Data Protection Act 1998. A small fee will be payable. If you
        would like a copy of the information held on you, please write to Best
        C. Veg Limited Unit 5-9 Leeside Industrial Estate Garman Road,
        Tottenham, London N17 0QH.
      </p>
      <br />
      <p>
        If you believe that any information we are holding on you is incorrect
        or incomplete, please write to or email us as soon as possible, at the
        above address. We will promptly correct any information found to be
        incorrect.
      </p>
      <br />
      <h2>List of cookies we collect</h2>
      <p>
        The table below lists the cookies we collect and what information they
        store.
      </p>
      <br />
      <table border="1">
        <thead>
          <tr>
            <th>COOKIE Name</th>
            <th>COOKIE Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>CART</td>
            <td>The association with your shopping cart.</td>
          </tr>
          <tr>
            <td>CATEGORY_INFO</td>
            <td>
              Stores the category info on the page, which allows to display of
              pages more quickly.
            </td>
          </tr>
          <tr>
            <td>COMPARE</td>
            <td>The items that you have in the Compare Products list.</td>
          </tr>
          <tr>
            <td>CURRENCY</td>
            <td>Your preferred currency.</td>
          </tr>
          <tr>
            <td>CUSTOMER</td>
            <td>An encrypted version of your customer ID with the store.</td>
          </tr>
          <tr>
            <td>CUSTOMER_AUTH</td>
            <td>An indicator if you are currently logged into the store.</td>
          </tr>
          <tr>
            <td>CUSTOMER_INFO</td>
            <td>An encrypted version of the customer group you belong to.</td>
          </tr>
          <tr>
            <td>CUSTOMER_SEGMENT_IDS</td>
            <td>Stores the Customer Segment ID.</td>
          </tr>
          <tr>
            <td>EXTERNAL_NO_CACHE</td>
            <td>A flag, which indicates whether caching is disabled or not.</td>
          </tr>
          <tr>
            <td>FRONTEND</td>
            <td>You session ID on the server.</td>
          </tr>
          <tr>
            <td>GUEST-VIEW</td>
            <td>Allows guests to edit their orders.</td>
          </tr>
          <tr>
            <td>LAST_CATEGORY</td>
            <td>The last category you visited.</td>
          </tr>
          <tr>
            <td>LAST_PRODUCT</td>
            <td>The most recent product you have viewed.</td>
          </tr>
          <tr>
            <td>NEW MESSAGE</td>
            <td>Indicates whether a new message has been received.</td>
          </tr>
          <tr>
            <td>NO_CACHE</td>
            <td>Indicates whether it is allowed to use cache.</td>
          </tr>
          <tr>
            <td>PERSISTENT_SHOPPING_CART</td>
            <td>
              A link to information about your cart and viewing history if you
              have asked the site.
            </td>
          </tr>
          <tr>
            <td>POLL</td>
            <td>The ID of any polls you have recently voted in.</td>
          </tr>
          <tr>
            <td>POLLN</td>
            <td>Information on what polls you have voted on.</td>
          </tr>
          <tr>
            <td>RECENTLY COMPARED</td>
            <td>The items that you have recently compared.</td>
          </tr>
          <tr>
            <td>SURF</td>
            <td>Information on products you have emailed to friends.</td>
          </tr>
          <tr>
            <td>STORE</td>
            <td>The store view or language you have selected.</td>
          </tr>
          <tr>
            <td>USER_ALLOWED_SAVE_COOKIE</td>
            <td>Indicates whether a customer is allowed to use cookies.</td>
          </tr>
          <tr>
            <td>VIEWED_PRODUCT_IDS</td>
            <td>The products that you have recently viewed.</td>
          </tr>
          <tr>
            <td>WISHLIST</td>
            <td>An encrypted list of products added to your Wishlist.</td>
          </tr>
          <tr>
            <td>WISHLIST_CNT</td>
            <td>The number of items in your Wishlist.</td>
          </tr>
          <tr>
            <td>GOOGLE ANALYTICS</td>
            <td>
              Google Analytics is a simple, easy-to-use tool that helps website
              owners measure how users interact with website content.
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <h2>Your consent</h2>
      <p>
        By using our website, you consent to us using the personal information
        you provide{" "}
        <a href="http://bestcateringuk.com/">http://bestcateringuk.com/</a> and
        affiliates as set out in this Privacy Policy. If we decide to change our
        Privacy Policy, we will post those changes on this page so that you are
        always aware of the way we collect, use, and disclose information.
      </p>
      <br />
      <h2>Contacting Us</h2>
      <p>
        If you have any questions about this privacy statement, you can contact
        us at{" "}
        <a href="mailto:sales@bestcateringuk.com">sales@bestcateringuk.com</a>{" "}
        or call us on 020 888 555 94.
      </p>
    </EachFeature>
  );
}

export default PrivacyPolicyContent;
