import styled from "styled-components";
// #region Style
const EachFeature = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  width: 100%;
  @media only screen and (max-width: 768px) {
    margin: 1rem 1rem;
  }
  & span {
    color: #212b36;
    text-align: left;
    font-family: "Roboto";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4rem;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 0.9rem;
    }
  }
  & h3 {
    color: #5f5f5f;
    text-align: left;
    font-family: "Roboto";
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 0.9rem;
    }
  }
  & h1 {
    color: #212b36;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    @media only screen and (max-width: 968px) {
      font-weight: 500;
      font-size: 1.5rem;
    }
  }
  & h2 {
    color: #1aa6e1;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    margin-left: 0.5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 500;
      font-size: 1.5rem;
    }
  }
  & h4 {
    color: #212b36;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    @media only screen and (max-width: 968px) {
      font-weight: 450;
      font-size: 1.5rem;
    }
    & span {
      color: #1aa6e1;

      text-align: left;
      font-family: "Roboto Slab";
      font-size: 2.18rem;
      font-style: normal;
      font-weight: 450;
      line-height: normal;
      @media only screen and (max-width: 968px) {
        font-weight: 450;
        font-size: 1.5rem;
      }
    }
  }
  & h5 {
    color: #1aa6e1;

    text-align: left;
    font-family: "Roboto Slab";
    font-size: 2.18rem;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    margin-left: 0.5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 450;
      font-size: 1.5rem;
    }
  }
  &:hover {
    opacity: 1;
  }
 
`;
const LabelDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const EachCategoryImage = styled.img`
  width: 100%;
  object-fit: contain;
  margin-bottom: 1.2rem;
  
`;
// #endregion
function PubAndBarsSectorComponent() {
  return (
    <EachFeature>
      <LabelDiv>
        <h4>
          ELEVATING <span>PUBS AND BARS</span> : YOUR COMPREHENSIVE SUPPLIER FOR
          ALL
        </h4>
      </LabelDiv>

      <h3>Your Trusted Partner For All Catering Needs</h3>
      <EachCategoryImage
        src={"/catalog/ShopBySector/bars.png"}
      ></EachCategoryImage>
      <span>
        Maximizing sales in pubs involves offering a variety of affordable,
        family-friendly meals along with popular crisps and bar snacks. Best
        Catering supports pub landlords by providing access to a wide assortment
        of delicious, locally sourced products, all conveniently available under
        one roof. We've got you covered from lunchtime favourites like tortillas
        and club sandwiches to hearty options such as steak, sausages, chicken,
        fries, chips, and water. Our extensive selection also includes a diverse
        range of beers, wines, spirits, and soft drinks, ensuring there's
        something for every customer. We supply all the key ingredients needed
        to keep your patrons returning for more.
      </span>
      <LabelDiv>
        <h4>
          WHAT WE <span>PROVIDE</span>
        </h4>
      </LabelDiv>
      <span>
        With over 2000 products at your disposal, we cater to every pub’s meal
        requirements. Our offerings include everything from well-known snacks
        and drinks to our own-brand value selections for chips, meats, and We
        offer a wide variety of nuts, including mixed walnuts, pistachios,
        hazelnuts, and almonds.
      </span>
      <span>
        Our Advance Order service ensures you receive a wide array of fresh,
        premium products. By partnering with local suppliers, we assist
        thousands of pubs and restaurants in enhancing their menus with
        top-quality items.
      </span>
      <LabelDiv>
        <h4>
          LEARN MORE ABOUT OUR <span>PREMIUM RANGE</span>
        </h4>
      </LabelDiv>
      <span>
        Furthermore, maintain a spotless establishment with our top-tier
        cleaning and hygiene supplies. Experience unmatched convenience with
        Best Catering.
      </span>
      <br />
      <span>
        <strong>
          {" "}
          ***Delivery days can differ based on where you are located.
        </strong>
      </span>
    </EachFeature>
  );
}

export default PubAndBarsSectorComponent;
