import styled from "styled-components";
import useWindowWidthAndHeight from "../../utilities/hooks/useWindowWidthAndHeight";
const FeaturesImage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  left: 0px;
  right: 0px;
  flex: 1;
  width: 100%;
  padding-bottom: 3rem;
  padding-top: 3rem;
  background-color: white;
  @media only screen and (max-width: 820px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 0px;
  right: 0px;
  flex: 1;
  width: 100%;
  max-width: 1280px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
`;
const HeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //margin-bottom: 2rem;
  //margin-top: 2rem;
  text-align: center;
  height: 163px;
  & h1 {
    color: #212b36;

    text-align: center;
    font-family: "Roboto Slab";
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 1.6rem;
    }
  }
  & p {
    color: #1aa6e1;

    text-align: center;
    font-family: "Roboto Slab";
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 0.5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 1.6rem;
    }
  }
  & span {
    color: #9a9a9a;
    text-align: center;
    font-family: "Roboto";
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 1rem;
    }
  }
`;
const LabelDiv = styled.div`
  display: flex;
  flex-direction: row;
`;
const EachFeature = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  opacity: .95;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    margin-bottom: .5rem;
  }

  & span {
    color: transparent;
    text-align: center;
    font-family: "Roboto Slab";
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 0.5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 500;
      font-size: 0.8rem;
    }
  }
  & p {
    color: #414549;

    text-align: center;
    font-family: "Roboto Slab";
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 0.5rem;
    @media only screen and (max-width: 968px) {
      font-weight: 500;
      font-size: 0.8rem;
    }
  }
  &:hover {
    opacity: 1;
  }
  @media only screen and (max-width: 768px) {
    width: 30%;
    //margin: 1rem auto;
  }
  @media only screen and (max-width: 420px) {
    width: 100%;
    //margin: 1rem auto;
  }
`;
const EachFeatureImage = styled.img`
  //width: 94px;
  height: 94px;
  object-fit: contain;
  @media only screen and (max-width: 768px) {
    //margin-bottom: 1rem;
    //height: 150px;
  }
`;
const NumberDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  background-color: #1aa6e1;
  text-align: center;
  &:hover {
    background-color: #ef1313;
    
  }
  & h2 {
    color: #fff;
    text-align: center;
    font-family: "Roboto";
    font-size: 1rem;
    font-weight: 600;
    line-height: normal;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 0.8rem;
    }
  }
`;
const NumberDiv2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  background-color: transparent;
  text-align: center;
  & h2 {
    color: #fff;
    text-align: center;
    font-family: "Roboto Slab";
    font-size: 1rem;
    font-weight: 600;
    line-height: normal;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 0.8rem;
    }
  }
`;
function OrderRoad() {
  const { width } = useWindowWidthAndHeight();
  return (
    <Container>
      <HeaderDiv>
        <LabelDiv>
          <h1>HOW TO</h1>
          <p>ORDER</p>
        </LabelDiv>
        <span>Follow the Steps</span>
      </HeaderDiv>

      <FeaturesImage>
        <EachFeature>
          <EachFeatureImage src="/catalog/OrderRoadImages/verify.png" />
          <NumberDiv>
            <h2>1</h2>
          </NumberDiv>
          <p>Register</p>
          <p>on the website</p>
        </EachFeature>
        {width > 768 && (
          <EachFeature>
            <EachFeatureImage src="/catalog/OrderRoadImages/right-arrow-1.png" />
            <NumberDiv2>
            <h2> </h2>
            </NumberDiv2>
            <span>.</span>
          </EachFeature>
        )}

        <EachFeature>
          <EachFeatureImage src="/catalog/OrderRoadImages/laptop.png" />
          <NumberDiv>
            <h2>2</h2>
          </NumberDiv>
          <p>Reply to our email and </p>
          <p>submit your documents</p>
        </EachFeature>
        {width > 768 && (
          <EachFeature>
            <EachFeatureImage src="/catalog/OrderRoadImages/right-arrow.png" />
            <NumberDiv2>
            <h2> </h2>
            </NumberDiv2>
            <span>.</span>
          </EachFeature>
        )}
        <EachFeature>
          <EachFeatureImage src="/catalog/OrderRoadImages/checkout.png" />
          <NumberDiv>
            <h2>3</h2>
          </NumberDiv>
          <p>Once approved</p>
          <p>create your order</p>
        </EachFeature>
        {width > 768 && (
          <EachFeature>
            <EachFeatureImage src="/catalog/OrderRoadImages/right-arrow-1.png" />
            <NumberDiv2>
            <h2> </h2>
            </NumberDiv2>
            <span>.</span>
          </EachFeature>
        )}
        <EachFeature>
          <EachFeatureImage src="/catalog/OrderRoadImages/credit-card.png" />
          <NumberDiv>
            <h2>4</h2>
          </NumberDiv>
          <p>Select your delivery date </p>  
          <p>and make your payment</p>
        </EachFeature>
        {width > 768 && (
          <EachFeature>
            <EachFeatureImage src="/catalog/OrderRoadImages/right-arrow.png" />
            <NumberDiv2>
            <h2> .</h2>
            </NumberDiv2>
            <span>.</span>
          </EachFeature>
        )}
        <EachFeature>
          <EachFeatureImage src="/catalog/OrderRoadImages/delivery.png" />
          <NumberDiv>
            <h2>5</h2>
          </NumberDiv>
          <p>Your order is </p>
          <p>on the way!</p>
        </EachFeature>
      </FeaturesImage>
    </Container>
  );
}

export default OrderRoad;
