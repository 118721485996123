import { useEffect, Fragment } from "react";
import { Menu, Person } from "@material-ui/icons";
import styled from "styled-components";
import DropDownMenu from "./DropDownMenu";
import { colors } from "../../res/values/values";
import { useNavigate } from "react-router-dom";
import useWindowWidthAndHeight from "../../utilities/hooks/useWindowWidthAndHeight";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as categoryActions from "../../redux/actions/categoryActions";
import { capitalizeFirstLetter } from "../../utilities/helpers";
import AccountMenuContent from "./AccountMenuContent";
import SubCategoryMenuContent from "./SubCategoryMenuContent";
const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  position: relative;
  max-width: 1280px;
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 1.2px;
  border-bottom-color: lightgray;
  border-bottom-style: solid;
`;
const Container = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
`;
const ContainerMobile = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  color: ${colors.primaryColor};
  padding: 10px;
  position: relative;
  //border-top: 1px solid lightgray;
`;
const MenuItem = styled.li`
  color: #212B36;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  //align-items: center;
  cursor: pointer;
`;
const MenuItemLink = styled.a`
  color: #212B36;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  color: #212b36;
  &:hover {
    color: ${colors.secondryColor};
    text-decoration: underline !important;
  }
`;
const CustomDivider = styled.div`
  margin-left: 0.7rem;
  margin-right: 0.7rem;
  width: 0.05rem;
  background-color: #212b36;
  height: 100%;
`;

function MenuBar(props) {
  const navigate = useNavigate();
  const { width } = useWindowWidthAndHeight();
  useEffect(() => {
    props.actions.getCategories();
  }, []);
  const handleLinkClick = (event, cat) => {
    event.preventDefault();
    navigate("/product-categories/" + cat.seodescription);
  };
  const renderMenuBarComponent = () => {
    let renderMenuBar;

    if (width > 915) {
      renderMenuBar = (
        <Content>
          <Container>
            {props.categories
              .filter((cat) => cat.indentation === "ItemCategory")
              .map((cat,index) => {
               const subCategories = props.categories.filter((subCat) => subCat.parentcode === cat.code)
               if(subCategories.length > 0) {
                return(
                  <Fragment key={index}>
                    <MenuItem>
                      <DropDownMenu
                        flexDirection="column"
                        text={capitalizeFirstLetter(cat.description)  }
                        contentWidth="80%"
                        fontSize="1rem"
                        fontWeight="400"
                        left={`${0*6+0.8}rem`}
                        top="1.1rem"
                        minHeight="10vh"
                        onClick={handleLinkClick}
                        category={cat}
                        contentLinks={<SubCategoryMenuContent subCategoryList={subCategories} />}
                      />
                    </MenuItem>
                    <CustomDivider />
                  </Fragment>
                 )
               }else{
                return (
                  <Fragment key={index}>
                    <MenuItem>
                      <MenuItemLink
                        href={"/product-categories/" + cat.seodescription}
                        onClick={(event) => handleLinkClick(event, cat)}
                        title={cat.seodescription}
                      >
                        {capitalizeFirstLetter(cat.description)}
                      </MenuItemLink>
                    </MenuItem>
                    <CustomDivider />
                  </Fragment>
                );
               }
               
              })}
          </Container>
        </Content>
      );
    } else {
      renderMenuBar = (
        <ContainerMobile>
          <DropDownMenu
            color="#212B36"
            fontSize="1rem"
            fontWeight="600"
            top="0rem"
            left="0rem"
            icon={<Menu />}
            text=""
            contentLinks={"mobileMenu"}
            setOpenStoreLocation={props.setOpenStoreLocation}
          />
        </ContainerMobile>
      );
    }

    return renderMenuBar;
  };
  return <div>{renderMenuBarComponent()}</div>;
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getCategories: bindActionCreators(
        categoryActions.getCategories,
        dispatch
      ),
    },
  };
}

function mapStateToProps(state) {
  return {
    categories: state.categoryListReducer,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(MenuBar);
